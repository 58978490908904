.footer {
	background-color: $color-blue-dark;
	color: $color-white-1;

	&__wrapper {
		column-gap: 4rem;
		display: grid;
		grid-template-columns: 2fr 2fr 1fr 2fr;

		@include respond(big-tablet) {
			grid-template-columns: 2fr 1fr;

			row-gap: 3rem;
			margin-bottom: 3rem;
		}

		@include respond(big-phone) {
			grid-template-rows: repeat(3, auto);
		}
	}
	&__info {
		@include respond(big-phone) {
			grid-column: 1/3;
		}
		& p {
			line-height: 1.8;
			margin-top: 1rem;
		}

		&--symbols {
			& img {
				width: 7rem;

				height: 7rem;
				object-fit: cover;
				border-radius: 1rem;
			}
		}
	}

	&__logo {
		& img {
			width: 32px;
			height: 32px;
		}

		& h2 {
			color: $color-white-1;
			font-weight: 900;
		}
	}

	&__title {
		font-size: 1.6rem;
		font-weight: 700;
		color: $color-white;
		margin-bottom: 2rem;
	}

	&__about {
	}

	&__link {
		padding-right: 5rem;
		@include respond(sm-laptop) {
			padding-right: 0;
		}
		& h3{
			margin-bottom: 4.2rem;
		}

		& > a {
			display: block;
			&:not(:last-child) {
				margin-bottom: 1.5rem;
			}
		}
	}

	&__contact {
		width: 40rem;
		height: 41rem;
		border-radius: 50%;
		background-color: $color-white-1;
		padding: 6rem 4rem;
		border: 1px solid $color-blue-dark;
		box-shadow: 0 0 0 5px $color-white-1;

		margin-top: -20rem;

		@include respond(big-tablet) {
			grid-column: 2/3;
			grid-row: 1/2;
		}
		@include respond(big-phone) {
			grid-column: 1/3;
			justify-self: center;
		}
		@include respond(phone) {
			width: 300px;
			height: 300px;
		}

		& h4 {
			font-size: 14px;
			color: $color-black;
			font-weight: 700;
		}

		& form {
			padding: 0 2rem;
		}

		& .input__label {
			color: #607d8b;
			font-size: 12px;
		}

		& .input {
			margin-bottom: 2rem;
			position: relative;
		}

		& .input__field {
			margin-top: 0;
			height: 32px;
		}

		& .input__message {
			position: absolute;
			bottom: -2rem;
			display: block;
		}

		& .button {
			height: 32px;
			font-size: 12px;
			width: 100%;
		}
	}

	&__social {
		padding-bottom: 2rem;
		border-bottom: 2px solid $color-green;
		@include respond(big-phone) {
			justify-content: center;
		}
		@include respond(phone) {
			justify-content: space-evenly;
		}
		&--item {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 4rem;
			height: 4rem;
			border-radius: 5px;
			background-color: $color-green;
		}
	}
}

// .footer {
// 	padding-top: 15px;

// 	&__wrapper {
// 		width: 100%;
// 		&::before {
// 			content: '';
// 			position: absolute;
// 			top: 0;
// 			right: -1.5rem;
// 			width: calc(100% + 1.5rem);
// 			height: 100%;
// 			background-color: $color-blue;
// 			border-radius: 0 12rem 0 0 / 0 7rem 0 0;
// 			z-index: -1;
// 		}
// 	}

// 	&__logo {
// 		width: 60rem;
// 		height: 80rem;
// 		border-radius: 50%;
// 		left: -44rem;
// 		top: -7.5rem;
// 		border: 1.4rem solid #fff;
// 		background-color: $color-green;
// 		@include display-flex(flex-start);
// 		padding-right: 4rem;
// 		@include respond(big-phone) {
// 			border: 0;
// 			height: 40rem;
// 			width: 40rem;
// 			left: -28rem;
// 			bottom: -2rem;
// 			top: auto;
// 			padding-right: 3rem;
// 		}
// 		& img {
// 			transform: translateY(-3rem);
// 			@include respond(big-phone) {
// 				max-width: 6rem;
// 				transform: translateY(0);
// 			}
// 		}
// 	}

// 	&__content {
// 		@include display-flex(space-between);
// 		width: 85%;
// 		min-height: 50rem;
// 		padding: 4rem 0;
// 		position: relative;
// 		@include respond(big-tablet) {
// 			flex-wrap: wrap;
// 			row-gap: 3rem;
// 			padding-bottom: 10rem;
// 		}
// 		@include respond(tablet) {
// 			width: 80%;
// 		}
// 		@include respond(big-phone) {
// 			justify-content: center;
// 			padding-bottom: 16rem;
// 			width: 100%;
// 		}
// 		&::after {
// 			content: '';
// 			position: absolute;
// 			width: 90%;
// 			height: 2px;
// 			background-color: $color-green;
// 			border-radius: 2px;
// 			bottom: 9%;
// 			left: 1rem;
// 			@include respond(big-phone) {
// 				bottom: 3rem;
// 				width: 80%;
// 				right: 2rem;
// 			}
// 		}
// 	}
// 	&__about {
// 		max-width: 360px;
// 		padding: 4.5rem 5rem;
// 		background-color: $color-white-light;
// 		border-radius: 40% / 50%;
// 		@include respond(big-tablet) {
// 			max-width: fit-content;
// 			border-radius: 12rem;
// 		}
// 		@include respond(phone) {
// 			border-radius: 40% / 50%;
// 		}

// 		& h2 {
// 			@include set-font(2.4rem, 700, $color-blue);
// 			margin-bottom: 1.5rem;
// 		}
// 		& p {
// 			text-align: center;
// 			line-height: 1.6;
// 		}
// 	}
// 	&__access {
// 		row-gap: 1.5rem;
// 		@include respond(big-phone) {
// 			flex-basis: 42%;
// 		}
// 		& h2 {
// 			@include set-font(2.4rem, 700, $color-white);
// 			margin-bottom: 2rem;
// 		}

// 		& > a {
// 			@include set-font(16px, 500, $color-white);
// 			@include display-flex;
// 			&::before {
// 				content: '';
// 				position: relative;
// 				width: 6px;
// 				height: 6px;
// 				background-color: $color-primary;
// 				border-radius: 2px;
// 				margin-left: 1.5rem;
// 			}
// 			&:hover {
// 				color: $color-primary;
// 			}
// 		}
// 	}
// 	&__link {
// 		row-gap: 3rem;
// 		@include respond(big-phone) {
// 			flex-basis: 45%;
// 			align-items: center;
// 		}
// 		& > a {
// 			@include set-font(2rem, 500, $color-white);
// 		}
// 	}

// 	&__symbols {
// 		column-gap: 2rem;
// 		@include respond(big-phone) {
// 			margin : {
// 				top: 4rem;
// 				left: auto;
// 				right: 5rem;
// 			}
// 		}
// 		@media only screen and (max-width: 22em) {
// 			margin-right: 1rem;
// 		}

// 		&--img {
// 			padding: 8px 4px;
// 			background-color: $color-white;
// 		}

// 		& img {
// 			max-width: 10rem;
// 			max-height: 10rem;
// 		}
// 	}
// }
