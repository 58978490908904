.sidebarMenu {
	// background: $color-blue-dark;
	// // position: fixed;
	// height: 100%;
	// border-top-left-radius: 123px;

	color: #ffffff;
	font-size: 14px;
	transition: all 0.4s;
	background: $color-section;
	border-top-left-radius: 14rem;

	padding-top: 6rem;
	padding-bottom: 4rem;

	display: flex;
	min-height: calc(100vh - 8rem);
	overflow: hidden;

	@include respond(big-tablet) {
		display: none;
	}
	@include respond(tablet) {
	}
	&__back {
	}
	.selected {
		color: $color-primary;
	}
	&__nestedul {
	}
	&__ul {
		margin-right: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	&__li {
		cursor: pointer;
		margin: 3rem 0;
		@include respond(big-tablet) {
			margin: 1rem;
			& > a {
				column-gap: 2rem;
			}
		}
		.menuIcon {
			svg {
				max-width: 2.6rem;
				max-height: 3rem;
				width: 100%;
				height: 100%;
				@include respond(big-tablet) {
					max-width: 2rem;
					max-height: 2.5rem;
				}
			}
		}
		&.exit {
			color: $color-error;
		}
		&.close {
			color: #ffefae;
		}
	}
	a {
		color: #ffffff;
	}
	p {
		padding-right: 16px;
		transition: all 0.4s;
		white-space: nowrap;
	}
}
.layout__sidebar {
	@include respond(sm-laptop) {
		// display:none;
	}
}
