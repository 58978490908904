.modal {
	min-width: 94.6rem;
	// max-height: 60rem;
	@include respond(tablet) {
		min-width: 0;
		// top: 3rem;
		@media only screen and (max-height: 62.5em) {
			top: 10rem;
		}
	}
	padding-bottom: 0;
	& .ant-modal-content {
		border-radius: 1.5rem;
		max-height: 90vh;
		overflow: auto;
	}
	& .ant-modal-body {
		padding: 0;
		border-radius: 1.5rem;
		position: relative;
		overflow: hidden;
	}

	& .ant-modal-header {
		color: $color-white;
		border-radius: 1.5rem 1.5rem 4px 4px;
		border-bottom: 2px solid $color-blue-dark;
	}

	& .ant-modal-title {
		font-weight: 600;
		font-size: 1.6rem;
		text-align: center;
	}

	& .ant-modal-close-x {
		height: 54px;
	}
}
.ant-modal-content {
	&::-webkit-scrollbar {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		opacity: 0;
		border-radius: 1.5rem;
	}
}
