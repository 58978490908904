/**
* * Rest style
*/
*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	box-sizing: border-box;
	font-size: 62.5%;  //10px===1rem
	@include respond(laptop) {
		font-size: 50%;
	}
	// overflow: hidden;
	direction: ltr;
	scroll-behavior: smooth;
	scroll-snap-type: mandatory;
	scroll-snap-points-y: repeat(300px);
	scroll-snap-type: y mandatory;
}

body {
	font-family: 'Vazir', 'sans-serif';
	color: $color-text;
	// overflow: auto;
	height: auto;
}

#root {
	direction: rtl;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	margin: 0;
	padding: 0;
}

img,
video {
	max-width: none;
}

.scrolling-effect {
	width: 100%;
	overflow: hidden;
}
// .ant-scrolling-effect {
// 	width: calc(100% - 1.7rem) !important;
// 	@include respond(big-tablet) {
// 		width: 100% !important;
// 	}
// }
.main {
	
}
