.ProduceRules {
	&__content {
		margin-top: 4rem;
		& > * {
			margin-bottom: 1.85rem;
		}
	}
	&__title {
		margin-right: 1.9rem;
		color: $color-text-gray-dark;
		width: 90%;
		font-size: 1.6rem;
		@include respond(tablet) {
			width: 80%;
			text-align: justify;
		}
		@include respond(phone) {
			width: 100%;
		}
	}
}
