.SkillsForm__row {
	@include respond(phone) {
		flex-direction: column;
		align-items: flex-start;
	}
	& .rate {
		margin-right: 4rem;
		& svg {
			width: 100%;
			height: 100%;
			max-width: 2.2rem;
		}
		& .ant-rate-star-first {
			width: 100%;
			// height: 100%;
			clip-path: inset(0 0 0 50%);
		}
		@include respond(phone) {
			margin: {
				right: 1rem;
				top: 2rem;
			}
		}
	}

	& .input {
		flex-basis: 50%;
		@include respond(tablet) {
			flex: 1 0 auto;
		}
	}
}
.SkillsDone{
	&__header{
     .ant-rate{
		 margin-bottom: -10px;
		 font-size:8px;
		 margin-left: 15px;
	 }
	}
}