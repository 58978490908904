.about {
	margin-top: 12rem;

	&__container {
		position: relative;
		margin-top: 6rem;
		@include d-flex-column(center);
		@include respond(tablet) {
			margin-top: 3rem;
		}
		&::before {
			content: '';
			background-color: $color-primary;
			width: 3px;
			height: 90%;
			margin: 1.2rem 0;
			border-radius: 2px;
			position: absolute;
			@include respond(tablet) {
				content: none;
			}
		}

		& > .dots {
			position: relative;
			min-height: 27rem;
			display: flex;
			align-items: flex-end;
			@include respond(laptop) {
				min-height: 24rem;
			}
			@include respond(tab-port) {
				min-height: 18rem;
			}
			@include respond(tablet) {
				min-height: auto;
			}
			&::before {
				content: '...';
				position: absolute;
				@include set-font(7rem, 900, $color-blue);
				right: 100%;
				bottom: -1.5rem;
			}
		}
	}

	&__row {
		@include display-flex(space-between, flex-end);
		position: relative;
		@include respond(laptop) {
			margin-top: 4rem;
		}
		@include respond(tablet) {
			flex-direction: column-reverse !important;
			align-items: center;
		}
		&.reverse {
			flex-direction: row-reverse;
			margin-top: -3rem;
			@include respond(tablet) {
				margin-top: 0;
			}
			& .about__pic {
				top: 22rem;
				@include respond(laptop) {
					top: 18rem;
				}
				@include respond(tab-port) {
					top: 12rem;
				}
			}
		}

		&--box {
			@include display-flex(space-between);
			flex-basis: calc(50% + 4.5rem);
			@include respond(tablet) {
				flex-basis: 100%;
				margin-bottom: 3rem;
			}
		}
	}

	&__circle {
		background-color: $color-green-dark;
		border-radius: 50%;
		@include display-flex(center);
		position: relative;
		@include set-font(1.6rem, bold, $color-blue);
		text-align: center;
		border: 1.2rem solid #fff;
		@include respond(tablet) {
			border-width: 0.5rem;
		}
		&.small {
			width: 9rem;
			height: 9rem;
		}
		&.big {
			width: 11rem;
			height: 11rem;
		}
		&.large {
			width: 12rem;
			height: 12rem;
			padding: 2rem;

			font-size: 14px;
			@include respond(tablet) {
				font-size: 12px;
			}
		}
	}
	&-heading {
		font-size: 2.5rem;
		font-weight: 700;
	}
	&-line {
		background-color: $color-primary;
		width: 3px;
		height: 15rem;
		margin: 1.5rem 0;
		border-radius: 2px;
		@include respond(big-phone) {
			display: none;
		}
	}
	&__pic {
		position: relative;
		top: -6rem;
		overflow: hidden;
		border-radius: 1.5rem;
		flex-basis: calc(50% - 10rem);
		max-height: 30rem;
		@include respond(laptop) {
			top: -10rem;
			flex-basis: calc(50% - 8rem);
		}
		@include respond(tablet) {
			top: 0 !important;
			flex-basis: 60%;
			max-width: 60%;
			margin-bottom: 3rem;
		}
		@include respond(phone){
			flex-basis: 90%;
			max-width: 90%;
		
		}

		// width: 50rem;
		// @include respond(big-tablet) {
		// 	width: calc(42vw - 3rem);
		// }
		// @include respond(big-phone) {
		// 	position: relative;
		// 	width: 100%;
		// 	flex-basis: 100%;
		// }

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: currentColor;
			opacity: 0.3;
		}
	}

	&__message {
		border-radius: 1.5rem;
		filter: drop-shadow(0 2px 15px rgba($color: #000, $alpha: 0.2));
		padding: 2rem;
		@include display-flex;
		background-color: $color-white;
		position: relative;
		flex-basis: calc(100% - 13rem);
		// @include respond(laptop) {
		// 	flex-basis: 80%;
		// }
		// @include respond(tab-port) {
		// 	flex-basis: 75%;
		// }
		// @include respond(tablet) {
		// 	flex-basis: calc();
		// }

		& p {
			@include set-font(1.6rem, 500, $color-text-light);
			line-height: 1.8;
		}
		& i {
			display: block;
			background-color: $color-white;
			width: 6rem;
			height: 3.5rem;
			border-radius: 3rem;
			position: absolute;
			left: -2.4rem;
			z-index: -1;
			&::after,
			&::before {
				content: '';

				// background-color: $color-blue;
				width: 3rem;
				height: 3rem;
				position: absolute;
				left: 6px;
				border: 12px solid $color-white;
				border-left: 0;
			}
			&::before {
				border-top: 0;
				top: -1.7rem;
				border-bottom-right-radius: 4rem;
				transform: rotateY(-29deg);
			}

			&::after {
				bottom: -1.7rem;
				border-top-right-radius: 4rem;
				border-bottom: 0;
				transform: rotateY(-30deg);
			}
		}

		&.rotate {
			& i {
				left: auto;
				right: -2.4rem;
				transform: rotate(180deg);
			}
		}
	}
}

// &--left {
// 	position: relative;
// 	@include display-flex;
// 	& .about__pic {
// 		top: 4rem;
// 		right: calc(100% + 5rem);
// 		color: $color-green;
// 	}
// 	& .about__box {
// 		right: calc(100% + 5.5rem);
// 		transform: rotateY(180deg);
// 		& p {
// 			transform: rotateY(-180deg);
// 		}
// 	}
// 	@include respond(big-tablet) {
// 		& .about__pic {
// 			right: calc(100% + 3rem);
// 		}
// 		& .about__box {
// 			right: calc(100% + 4rem);
// 		}
// 	}
// 	@include respond(big-phone) {
// 		& .about__box {
// 			right: auto;
// 		}
// 		& .about__pic {
// 			right: auto;
// 		}
// 	}
// }

// &--right {
// 	position: relative;
// 	@include display-flex;

// 	& .about__box {
// 		left: calc(100% + 5.2rem);
// 	}
// 	& .about__pic {
// 		bottom: 4rem;
// 		left: calc(100% + 5rem);
// 		color: #1a6577;
// 	}
// 	@include respond(big-tablet) {
// 		& .about__box {
// 			left: calc(100% + 4rem);
// 		}
// 		& .about__pic {
// 			left: calc(100% + 3rem);
// 		}
// 	}
// 	@include respond(big-phone) {
// 		& .about__box {
// 			left: auto;
// 		}
// 		& .about__pic {
// 			bottom: auto;
// 			left: auto;
// 		}
// 	}
// }

// &__timeline {
// 	@include d-flex-column;
// 	margin-top: 6rem;
// 	&--circle {
// 		@include d-flex-column(center);
// 		@include set-font(1.6rem, bold, $color-blue);
// 		position: relative;
// 		& span {
// 			width: 9.6rem;
// 			height: 9.6rem;
// 			border-radius: 50%;
// 			background-color: $color-green-dark;
// 			@include display-flex(center);
// 		}
// 		&.line {
// 			// &::after {
// 			// 	content: '';
// 			// 	height: 15rem;
// 			// 	width: 3px;
// 			// 	border-radius: 2px;
// 			// 	background-color: $color-primary;
// 			// 	margin-top: 1.2rem;
// 			// }
// 			&::before {
// 				content: '';
// 				height: 15rem;
// 				width: 3px;
// 				border-radius: 2px;
// 				background-color: $color-primary;
// 				margin-bottom: 1.2rem;
// 			}
// 		}
// 	}
// 	&--item {
// 		@include display-flex(center, baseline);
// 		margin-top: 1.2rem;

// 		& .about__timeline--circle span {
// 			width: 7rem;
// 			height: 7rem;
// 		}
// 	}
// 	&--pic {
// 		flex-basis: 42%;
// 		max-width: 42%;
// 		max-height: 30rem;
// 		overflow: hidden;
// 		border-radius: 1.5rem;
// 		align-self: flex-start;

// 		& img {
// 			object-fit: cover;
// 		}
// 	}
// 	&--content {
// 		flex-basis: 42%;
// 		max-width: 42%;
// 		padding: 2rem 2.5rem;
// 		border-radius: 1.5rem;
// 		position: relative;
// 		filter: drop-shadow(0 2px 15px rgba($color: #000, $alpha: 0.2));
// 		background-color: $color-white;
// 		@include display-flex;
// 		top: -2rem;
// 		& i {
// 			display: block;
// 			background-color: $color-white;
// 			width: 6rem;
// 			height: 2.5rem;
// 			border-radius: 3rem;
// 			position: absolute;
// 			left: -2.4rem;
// 			&::after,
// 			&::before {
// 				content: '';

// 				// background-color: $color-blue;
// 				width: 3rem;
// 				height: 3rem;
// 				position: absolute;
// 				left: 6px;
// 				border: 12px solid $color-white;
// 				border-left: 0;
// 			}
// 			&::before {
// 				border-top: 0;
// 				top: -1.8rem;
// 				border-bottom-right-radius: 4rem;
// 				transform: rotateY(-29deg);
// 			}

// 			&::after {
// 				bottom: -1.75rem;
// 				border-top-right-radius: 4rem;
// 				border-bottom: 0;
// 				transform: rotateY(-30deg);
// 			}
// 		}
// 	}
// 	& p {
// 		@include set-font(1.6rem, 500, $color-text-light);
// 		line-height: 1.8;
// 	}
// }
