.contact {
	&-wrapper {
		display: grid;
		grid-template-columns: 55% 45%;
		grid-template-rows: 10rem auto;
		column-gap: 1rem;
		padding-bottom: 5rem;
		background-color: $color-gray;
		min-height: 57rem;
		
		@include respond(big-desktop) {
			max-width: 120rem;
		}
		@include respond(tablet) {
			grid-template-columns: 1fr;
			grid-template-rows: repeat(3, auto);
			margin-top: 10rem;
			width: fit-content;
			padding: 4rem;
		}
		@include respond(phone) {
			padding: 3rem 2rem;
		}

		& .title__box {
			grid-column: 1 / 2;
			grid-row: 1/2;
			justify-self: end;
			width: auto;
			@include respond(tablet) {
				justify-self: center;
				margin-top: -18rem;
			}
			@include respond(phone) {
				margin-top: -15rem;
			}
		}
	}

	&-form {
		grid-column: 1 / 2;
		grid-row: 2/3;
		max-width: 65%;
		width: 100%;
		justify-self: end;
		margin-left: 3rem;
		@include respond(tablet) {
			justify-self: center;
			grid-row: 3/4;
			margin-bottom: 18rem;
			max-width: 100%;
			margin-left: 0;
		}
		@include respond(phone) {
			margin-bottom: 14rem;
		}
		& > .input {
			margin-bottom: 2rem;
		}
	}
	&-img {
		// transform: translate(-15%, -10%);
		grid-column: 2 / 3;
		grid-row: 1/3;
		margin: 5.5rem 5rem 0;
		@include respond(tablet) {
			grid-column: 1/2;
			grid-row: 2/3;
			max-width: 50rem;
			justify-self: center;
			margin: 0;
			margin-bottom: 2rem;
		}
		& img {
			object-fit: contain;
		}
	}
	&-bold {
		font-weight: bold;
	}
	&-button {
		align-self: stretch;
		//width: 100%;
		text-align: center;
	}
	&-heading {
		font-size: 2.5rem;
		font-weight: bold;
	}
}
