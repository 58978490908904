.container-lg {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	padding-right: 5rem;
	padding-left: 5rem;
	height: inherit;
	max-width: 1920px;

	@include respond(big-desktop) {
		// max-width: 1800px;
	}
	@include respond(phone) {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

.container {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	padding-right: 3rem;
	padding-left: 3rem;

	max-width: 1450px;
	@include respond(laptop) {
		max-width: 1240px;
	}
	@include respond(phone) {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

.d-flex {
	@include display-flex;
}

.d-flex-col {
	@include d-flex-column;
}
.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.d-flex-align {
	display: flex;
	align-items: center;
}

.d-flex-space {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.b-radius {
	&-3 {
		border-radius: 3rem;
	}

	&-2 {
		border-radius: 2rem;
	}

	&-1 {
		border-radius: 1rem;
	}
}

.custom-scrollbars {
	scrollbar-color: $color-disable transparent;
	scrollbar-width: thin;
	// overflow-y: auto;
	&::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $color-disable;
		border-radius: 4px;
	}
}

.full-width {
	width: 100%;
}

.avatar {
	width: 4.4rem;
	height: 4.4rem;
	border-radius: 2rem;
	flex: 0 0 auto;
}

//margin bottom
.mr-bt-sm {
	margin-bottom: 2rem;
}

.mr-bt-md {
	margin-bottom: 3rem;
}

.mr-bt-av {
	margin-bottom: 4rem;
}

.mr-bt-bg {
	margin-bottom: 6rem;
}

.mr-bt-hg {
	margin-bottom: 8rem;
}

.ant-breadcrumb,
.ant-breadcrumb > span:last-child,
.ant-breadcrumb-separator {
	color: $color-black-light-1;
}

.green-circle {
	width: 2.6rem;
	height: 2.6rem;
	background-color: $color-green-dark;
	border-radius: 50%;
	@include set-font(1.6rem, 500, $color-blue);
	@include display-flex(center);
}

.image {
	width: 100%;
	height: 100%;
}

.half__circle {
	width: 5.3rem;
	height: 5.3rem;
	background: $color-green-dark;
	transform: rotate(-90deg);
	border-radius: 50%;
	margin-right: -2.7rem;
}

.circle_img {
	border-radius: 50%;
}

.skeleton {
	display: flex;
	flex-direction: column;

	& .ant-skeleton-header {
		padding-left: 0;
	}

	& .ant-skeleton-avatar {
		width: 100%;
		border-radius: 1.5rem !important;
		margin-bottom: 1rem;
	}
}
.ant-comment-inner {
	padding: 0.9rem 0 !important;
}
.ant-skeleton {
	width: 100% !important;
}
.ant-skeleton-element .ant-skeleton-button-lg {
	width: 100%;
}
.ace_scrollbar-v {
	&::-webkit-scrollbar {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		opacity: 0;
		border-radius: 1.5rem;
	}
}
.ace_editor {
	overflow: auto !important;
	// max-height: 52.5rem !important;
	&::-webkit-scrollbar {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		opacity: 0;
		border-radius: 1.5rem;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		opacity: 0;
		border-radius: 1.5rem;
	}
}
