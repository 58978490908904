.Example {
    &__container {
        background-color: $color-white;
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem;
        padding: 3.2rem 2.7rem 4.2rem 2.2rem;
        margin-top: 1.8rem;
       

    }
}
