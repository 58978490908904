.Sarfasl {
	&__form {
		height: auto;
		padding-bottom: 2rem;
	}

	&__sample {
		background-color: $color-blue;
		border-radius: 4rem;
		padding-right: 2.9rem;
		padding: 0.2rem 2.9rem 0.2rem 0.2rem;
		margin: 3rem 0;

		& > *:first-child {
			color: $color-white;
			font-size: 1.6rem;
		}
	}

	&__title {
		@include set-font(1.6rem, 600, $color-blue-dark);
	
	}

	&__sampleLinkBox {
		background-color: $color-white;
		border-radius: 4rem;
		width: 85%;
		height: 100%;
		padding: 1rem 0;
		direction: ltr;

		a {
			color: $color-primary;
			font-size: 1.6rem;
		}
	}

	&__Accordionbox {
		margin-top: 4rem;

		& > *:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	&__Accordionnumber {
		color: $color-primary;
		border-radius: 50%;
		border: 1px solid $color-primary;
		width: 2.1rem;
		height: 2.1rem;
		font-size: 1.5rem;
		@include display-flex(center);
		p{
			line-height: 0;
		}
	}

	&__Accordiondone {
		width: 2rem;
		height: 2rem;
		text-align: right;
		border: 1px solid $color-success;
		color: $color-success;
		border-radius: 50%;
		position: relative;

		.fa-check {
			font-size: 2rem;
			position: absolute;
			right: -0.5rem;
			top: -0.2rem;
		}
	}

	&__Accordiontxtbox {
		& > *:last-child {
			margin-right: 1rem;
		}
	}

	&__AccordionTimeBox {
		p {
			font-size: 1.6rem;
			//margin-left: 1.5rem;
		}
	}

	&__Accordionclock {
		width: 2.4rem;
		height: auto;
	}

	&__AccordionLock {
		width: 1.6rem;
		height: auto;
		margin-left: 1.5rem;
	}

	&__commentBox {
		margin-top: 5.7rem;
	}

	&__commentpart {
		height: 65.75rem;
	}

	&__AccordionCenter {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
	}

	&__content {
		margin: 0 2rem;
		&:not(:last-of-type) {
			margin-bottom: 1.5rem;
		}
		&--title{
			&:hover{
				color:$color-primary
			}
		}
	}

	&__AccordionItem {
		display: flex;
		align-items: center;
		column-gap: 1rem;

		&--time {
			@include display-flex;
			column-gap: 0.6rem;
			margin-right: auto;
			& time {
				color: $color-text;
			}
		}

		img {
			max-width: 20px;
			max-height: 20px;
		}

		i {
			position: absolute;
			width: 10%;
			height: 2px;
			background-color: $color-text-light;
			//background-color: black;
			transform: rotate(-45deg) scale(0);
			transition: transform 200ms;
		}

		&.lock {
			i {
				transform: rotate(-45deg) scale(1);
			}
		}
	}

	&__btn {
		& svg {
			max-width: 20px;
			max-height: 20px;
		}

		& i {
			position: absolute;
			width: 64%;
			height: 2px;
			background-color: $color-text-light;
			transform: rotate(-45deg) scale(0);
			transition: transform 200ms;
		}

		&.lock {
			& i {
				transform: rotate(-45deg) scale(1);
			}
		}
	}
}
