//news
.news {
	padding-bottom: 3rem;
	& .title__box{
		margin-bottom: 3.5rem;
	}
	&-grid {
		// @include display-grid(3.5rem 3rem, 32rem);
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 3.5rem 3rem;
		min-height: 50vh;
		@include respond(sm-laptop) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include respond(tablet) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include respond(big-phone) {
			grid-template-columns: 1fr;
		}
	}
	&-button {
		align-self: flex-end;
		margin-left: 0;
		margin-right: auto;
		width: max-content;
		height: 36px;
		font-size: 14px;
	}
	&-info {
		@include display-flex(space-between);
	}

	&__item {
		cursor: pointer;
		border-radius: 2rem;
		overflow: hidden;
		background-color: #fff;
		box-shadow: $box-shadow;
		padding: 1rem;
		transition: all 300ms;

		&:hover {
			transform: translateY(-6px);

			& h2 {
				color: $color-primary;
			}
		}
		&--pic {
			& img {
				border-radius: 1rem;
				@extend .image;
				max-height: 250px;
				object-fit: cover;
			}
		}

		&--content {
			padding: 1.5rem 1rem;
			display: flex;
			flex-direction: column;

			& > h2 {
				@include set-font(1.8rem, 600, $color-text);
				transition: all 300ms;
				margin-bottom: 6px;
			}
			& > p {
				display: -webkit-box;
				line-clamp: 3;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				@include set-font(14px, 500, $color-text-light);
				margin-bottom: 1.5rem;
			}
		}

		&--info {
			@include display-flex(space-between);
			column-gap: 5px;
			color: $color-text-light;
			margin-top: auto;
		}

		&--date {
			@include display-flex;
			column-gap: 5px;
			color: $color-text-light;
		}
		&--loading {
			@include d-flex-column('', stretch);

			@include respond(sm-laptop) {
				&:nth-child(4) {
					display: none;
				}
			}
			@include respond(tablet) {
				&:nth-child(3) {
					display: none;
				}
			}
			@include respond(big-phone) {
				&:nth-child(2) {
					display: none;
				}
			}

			
			& .ant-skeleton-avatar {
				height: 250px;
				border-radius: 1rem !important;
			}
			& .ant-skeleton-content {
				padding: 1rem;
			}
		}
	}

	&__loading {
		&--details {
			& .ant-skeleton-avatar {
				height: 45rem;
			}

			& .ant-skeleton-title {
				height: 3.2rem;
			}
		}
		&--lastNews {
			padding: 8px;
			border-radius: 1rem;
			background-color: #fff;
			box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.15);
			& .ant-skeleton-avatar {
				border-radius: 1rem !important;
			}
		}

		&--related {
			padding: 1.5rem;
			border-radius: 2rem;
			box-shadow: $box-shadow;
			& .ant-skeleton-avatar {
				border-radius: 1rem !important;
				height: 20rem;
			}

			& .ant-skeleton-title {
				width: 70%;
			}
		}
	}

	&__details {
		padding: 1.5rem;
		// border: 1px solid $color-gray;
		box-shadow: 0 2px 16px 2px rgba(0, 0, 0, 0.12);

		border-radius: 2rem;
		&--pic {
			margin-bottom: 1.5rem;
			animation: fade 600ms ease forwards;
			& img {
				@extend .image;
				border-radius: 1.5rem;
				max-height: 45rem;
				object-fit: cover;
			}
		}

		&--info {
			@include display-flex;
			column-gap: 2rem;
			padding: 5px 1.5rem;
			border-radius: 1rem;
			background-color: $color-gray;
			width: fit-content;
			margin-bottom: 2rem;
			animation: fade 600ms ease forwards;

		}

		&--content {
			padding: 0 1rem;
			animation: fade 600ms ease forwards;
			& > p {
				@include set-font(16px, 500, $color-text);
				line-height: 1.8;
				margin-bottom: 5px;
			}

			& h1,
			& h2,
			& h3,
			& h4,
			& h5,
			& h6 {
				color: $color-text;
				font-weight: 700;
				margin: 1.5rem 0;
			}

			& h1 {
				font-size: 2.8rem;
			}
			& h2 {
				font-size: 2.6rem;
			}
			& h3 {
				font-size: 2.4rem;
			}
			& h4 {
				font-size: 2.2rem;
			}
			& h5 {
				font-size: 2rem;
			}
			& h6 {
				font-size: 1.8rem;
			}
		}
	}

	&__related {
		padding: 1.5rem;
		border-radius: 2rem;
		background-color: #fff;
		box-shadow: $box-shadow;
		transition: all 300ms;
		display: block;

		&:hover {
			& h4 {
				color: $color-primary;
			}
		}

		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
		& img {
			border-radius: 1rem;
			animation: fade 600ms ease forwards;

		}

		& h4 {
			margin: 1rem 1rem 0;
			font-size: 16px;
			transition: all 300ms;
			animation: fade 600ms ease forwards;

		}
	}
}
//card

//single title
.Title {
	margin-bottom: 3rem;
	display: grid;
	grid-template-columns: 4fr 1fr;
	gap: 3rem;
	width: 100%;
	min-height: 60vh;
	&-details {
		background-color: rgba($color-gray-dark, 0.5);
		border-radius: 0 100px 100px 0;
		padding: 1rem;
		color: $color-text;
		padding: 0.8rem 2rem;
		animation: fade 600ms ease forwards;

		& img,
		& span {
			margin-left: 1rem;
		}
	}
	//media queries

	// stacking contents
	@include respond(phone) {
		grid-template-columns: 100%;
	}
	// moving headings
	&-heading {
		// margin: 10rem 4rem 2.5rem 0;
		margin-right: 4rem;
		margin-bottom: 2.5rem;
		@include set-font(2rem, bold);

		@include respond(phone) {
			margin-right: 2rem;
		}

		&-sidebar {
			@include set-font(1.6rem, 700);
			margin-right: 1rem;
			margin-bottom: 1.5rem;
		}
	}
	&-heads {
		padding: 8px;
		margin-bottom: 1rem;
		border-radius: 1rem;
		color: $color-text;
		font-size: 1.6rem;
		background-color: #fff;
		box-shadow: 0 2px 12px 0px rgba(0, 0, 0, 0.15);
		animation: fade 600ms ease forwards;

		&:hover {
			& h4 {
				color: $color-primary;
			}
		}
		@include respond(phone) {
			text-align: center;
		}
		& img {
			@extend .image;
			max-width: 5rem;
			border-radius: 5px;
		}
		& h4 {
			font-size: 16px;
			transition: all 300ms;
		}
	}
	&-title {
		font-size: 2.5rem;
	}

	&-paging {
		width: max-content;
		margin: 3rem auto;
	}
	&-cards {
		@include respond(phone) {
			padding: 0 6rem;
		}
	}
	&-linked {
		& .card-content {
			padding: 2.5rem;
		}
	}
}

//details
.details {
	@include d-flex-column('', '');
	background-color: white;
	border-radius: 15px;
	box-shadow: $box-shadow;
	&-container {
		position: relative;
	}
	&-img {
		//position: absolute;
		//top: 0;
		//left: 0;
		width: 100%;
		height: 100%;
	}
	&-info {
		position: absolute;
		bottom: 2rem;
		left: 2px;
	}
}

.orange {
	color: $color-primary;
}

.text-color {
	color: $color-text-light;
}
.light {
	font-weight: normal;
	margin-left: 1rem;
	font-size: 13px;
}
.margin-left {
	margin-left: 3rem;
}
.latest-news-link {
	font-size: 14px;
}
