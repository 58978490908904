.Course {
    margin-top: 2rem;
    &__container {
        grid-template-columns: 0.7fr 2.5fr 1fr;
        margin-top: 2.7rem;
        & > *:last-child {
            position: relative;
        }
        // @include respond(big-tablet) {
        //     grid-template-columns: 1fr 2.5fr 1fr;
        // }
        // @include respond(tab-port) {
        //     grid-template-columns: 1fr 2fr 1fr;
        // }
        @include respond(big-tablet) {
            display: flex;
            flex-direction: column-reverse;
        }
    }
    &__Comment {
        margin-top: 5rem;
        & .ant-tabs-tabpane{
            padding-bottom: 2rem;
        }
    }
    &__sideBar {
        min-height: 100vh;
        @include respond(big-tablet) {
            height: auto;
            min-height: auto;
            margin-bottom: 3.4rem;
        }
    }
}


.ant-tabs-tab{
    height: 30px !important;
}
.courses{
    &__grid{
        @include respond(sm-laptop) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @include respond(tablet) {
            grid-template-columns: 1fr 1fr;
        }
        @include respond(big-phone) {
            grid-template-columns: 1fr ;
        }
    }
}
.Card__empthy{
    flex-direction: column;
    img{
        width: 300px;
    }

}