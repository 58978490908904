.codeeditorBox {
    width: 100%;
    height: 10rem;
    overflow-y: auto;
    direction: rtl;
    &__activbtn {
        background-color: $color-editor-back;
        color: $color-yellow-dark;
        border: none;
        // border-bottom: 1px solid $color-yellow-dark;
        // border-right: 1px solid $color-yellow-dark;
        padding: 0.5rem 1rem;
        border-top-left-radius: 0.45rem;
        border-top-right-radius: 0.45rem;
        font-size: 1.5rem;
        @include respond(tablet) {
            font-size: 1rem;
            padding: 0.7rem 1rem;
        }
        @include respond(smallest-phone) {
            font-size: 1rem;
            padding: 0.7rem 0.25rem;
        }
        &:focus {
            outline: none;
        }
    }
    &__disactivbtn {
        color: $color-white;
        background-color: transparent;
        border: none;
        // border-right: 1px solid $color-black;
        // border-left: 1px solid $color-yellow-dark;
        font-size: 1.5rem;
        padding: 0.15rem 1rem;
        @include respond(tablet) {
            font-size: 1rem;
        }
        @include respond(smallest-phone) {
            font-size: 1rem;
            padding: 0.7rem 0.25rem;
        }
    }
    &__BtnBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-editor-back;
        border-bottom: 1px solid $color-yellow-dark;
        border-bottom: 1px solid $color-yellow-dark;
    }
    &__BtnBox1 {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        background-color: $color-editor-back;
        border-bottom: 1px solid $color-yellow-dark;
        border-bottom: 1px solid $color-yellow-dark;
    }
    &__btnDon {
        cursor: pointer;
        border-radius: 2rem;
        color: $color-white;
        display: flex;
        align-items: center;
        &:focus {
            outline: none;
        }
        margin-right: 1rem;
        @include respond(tablet) {
            font-size: 1rem;
        }
    }
    &__fulScreen {
        width: 2rem;
        height: 2rem;
        border: 1px dashed $color-yellow-dark;
        background-color: transparent;
        cursor: pointer;
        margin-right: 1rem;
        border-radius: 0 !important;
        @include respond(tablet) {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    &__codeeditor {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 3rem;
        margin-bottom: 3rem;
        overflow: hidden;
        border-radius: 15px;
        @include respond(tablet) {
            grid-template-columns: 1fr;
        }
        & > *:first-child {
            border-right: 1px solid $color-green-light;
        }
        &-btnBox {
            background-color: $color-editor-back;
            border-bottom: 1px solid $color-yellow-dark;
            border-bottom: 1px solid $color-yellow-dark;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.5rem;
            p {
                display: inline-block;
                padding: 6px 1rem;
                color: $color-yellow-dark;
            }
            button {
                cursor: pointer;
                border-radius: 2rem;
                color: $color-white;
                display: flex;
                align-items: center;
                &:focus {
                    outline: none;
                }
            }
        }
        &-btnBox1 {
            background-color: $color-editor-back;
            border-bottom: 1px solid $color-yellow-dark;
            border-bottom: 1px solid $color-yellow-dark;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.2rem;
            p {
                display: inline-block;
                padding: 0.5rem 1rem;
                color: $color-white;
            }
            button {
                cursor: pointer;
                border-radius: 2rem;
                color: $color-white;
                display: flex;
                align-items: center;
                &:focus {
                    outline: none;
                }
            }
        }
        &-btnBox2 {
            background-color: $color-editor-back;
            border-bottom: 1px solid $color-yellow-dark;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.2rem;
            padding-right: 10rem;
        }
        &-btncopy {
            background-color: transparent;
            border: 1px solid $color-white;
            padding: 0rem 1rem;

            margin-right: 1rem;
            .fa-copy,
            .fa-check {
                color: $color-white;
                margin-left: 0.5rem;
            }
            @include respond(tablet) {
                font-size: 1rem;
                padding: 0rem 0.8rem;
            }
        }
        &-btncopy1 {
            background-color: transparent;
            border: 1px solid $color-white;
            padding: 0rem 1rem;
            .fa-copy,
            .fa-check {
                color: $color-white;
                margin-left: 0.5rem;
            }
            @include respond(tablet) {
                font-size: 1rem;
                .fa-copy,
                .fa-check {
                    color: $color-white;
                    margin-left: 0.5rem;
                }
            }
        }
        &-btndone {
            background-color: $color-green-light;
            border: none;
            margin-right: 0.8rem;
            padding: 0rem 1rem;
            .fas {
                color: $color-white;
                margin-left: 0.5rem;
            }
            @include respond(tablet) {
                .fas {
                    margin-left: 0.5rem;
                }
                padding: 0rem 0.5rem;
            }
            @include respond(smallest-phone) {
                margin-right: 0;
            }
        }
        &-btndonedis {
            background-color: $color-grey-dark-1;
            border: none;
            margin-right: 0.8rem;
            padding: 0rem 1rem;
            .fas {
                color: $color-white;
                margin-right: 0.5rem;
            }
            @include respond(tablet) {
                margin-left: 0.5rem;
            }
        }
    }
    .ace_print-margin {
        left: 0 !important;
    }
}
.ace-monokai .ace_print-margin {
    left: 0 !important;
}
