.card-bg {
	display: flex;
	justify-content: space-between;
	background-color: $color-white;
	box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
	border-radius: 15px;
	overflow: hidden;
	position: relative;
	height: 300px;
	margin: 5px auto;
	padding: 15px;
	// width: 100%;
	cursor: pointer;
	transition: all 400ms;
	@include respond(laptop) {
		height: 280px;
	}
	@include respond(tablet) {
		width: 96%;
	}
	@include respond(big-phone) {
		flex-direction: column;
		height: 510px;
	}
	&:hover {
		& .card-bg-title {
			color: $color-primary;
		}
	}

	&-loading {
		background-color: $color-white;
		box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
		border-radius: 15px;
		position: relative;
		height: 260px;
		margin: 5px auto;
		padding: 15px;
		display: flex !important;
		align-items: center;
		@include respond(laptop) {
			height: 280px;
		}
		@include respond(tablet) {
			width: 96%;
		}
		@include respond(big-phone) {
			flex-direction: column;
			height: 510px;
		}

		& > .ant-skeleton {
			height: 100%;
			margin-left: 1.5rem;
			& .ant-skeleton-avatar {
				height: 100%;
				width: 27rem;
				border-radius: 1.5rem;
				animation-name: ant-skeleton-loading-rtl;
			}
			@include respond(big-phone) {
				margin-left: 0;
				width: 100%;
				& .ant-skeleton-avatar {
					width: 100%;
				}
			}
		}

		&-content {
			width: 100%;
		}

		&--footer {
			margin-top: 3rem;
			@include display-flex;

			& .ant-skeleton-avatar {
				border-radius: 50%;
			}

			& .ant-skeleton-title {
				width: 35%;
			}

			& .ant-skeleton:nth-child(2) {
				flex-basis: 40%;
				margin-top: 4px;
			}

			& .ant-skeleton-button {
				width: 100%;
				height: 16px;
				border-radius: 4px;
				animation-name: ant-skeleton-loading-rtl;
			}
		}
	}
	&-off-hide {
		display: none;
	}
	&-off-show {
		position: absolute;
		font-size: 12px;
		background-color: $color-primary;
		padding: 5px 1.5rem;
		border-bottom-left-radius: 1rem;
		color: #fff;
		top: 0px;
		z-index: 100;
		right: 0;
	}

	&-title {
		@include set-font(2.4rem, bold, $color-text);
		transition: all 400ms;
	}
	&-des {
		font-size: 14px;
		color: rgba($color: $color-text, $alpha: 0.8);
		display: -webkit-box;
		line-clamp: 3;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		line-height: 1.6;
	}
	&--box {
		top: 15px;
		position: absolute;
		left: 14px;
		display: flex;
		align-items: center;
	}
	&--shopingcard {
		border-radius: 50%;
		&.wishList--full {
			& path {
				stroke: $color-primary;
				fill: rgba($color: $color-primary, $alpha: 0.08);
			}
		}
	}

	&--heart {
		border-radius: 50%;
		margin-right: 8px;
		&.wishList--full {
			& path {
				stroke: $color-primary;
				fill: $color-primary;
			}
		}
		& button {
			&:hover {
				color: $color-primary;
			}
		}
	}
	button {
		background-color: #fff;
		color: $color-text;
		box-shadow: 0px 0px 10px 0px rgba($color-blue-2, 0.2);
		& path {
			fill: #fff;
		}
	}

	&-footer {
		border-top: 1px solid rgba(229, 229, 229, 0.8);
		padding-top: 15px;
		margin-top: 10px;
		width: 100%;
	}

	&-price {
		display: flex;
		flex-direction: column;
		width: 47%;
		& .discount {
			& .ant-statistic-content {
				font-size: inherit !important;
			}
			& .ant-statistic-content-value {
				font-size: 14px;
				color: $color-text-black-light-2;

				&::after {
					height: 1px !important;
				}
			}
		}
		& .isSuccess {
			& .ant-statistic-content {
				font-size: 1.8rem !important;
			}

			& .ant-statistic-content-suffix {
				font-size: 14px;
				color: $color-text-black-light-3;
			}
		}
	}

	&-time {
		display: flex;
		margin-right: 6px;
		margin-bottom: -3px;
		color: rgba($color: $color-text, $alpha: 0.8);
		align-items: center;
	}
	&-img {
		height: auto;
		position: relative;
		overflow: hidden;
		transition: all 0.5s ease-in-out;

		&-title {
			img {
				width: 100%;
				height: 186px;
				border-radius: 15px;
			}

			h4 {
				font-size: 4rem;
				color: #fff;
				margin-left: 0.5rem;
				white-space: nowrap;
			}
		}

		&-pic {
			display: flex;
			align-items: center;
			width: fit-content;

			&:hover {
				& span {
					color: $color-primary;
					font-weight: 600;
				}

				& img {
					box-shadow: 0 0 2px 2px $color-green;
				}
			}
			span {
				margin-right: 1rem;
				position: relative;
				z-index: 9;
				font-size: 14px;
				transition: all 300ms;
			}

			img {
				position: relative;
				z-index: 11;
				transition: all 300ms;

				width: 35px;
				height: 35px;
				border-radius: 50%;
				box-shadow: 0px 0px 5px 0px rgba(70, 190, 167, 0.5);
			}
		}
	}

	&-info-row {
		display: flex;
		align-items: center;
		grid-gap: 2rem;
		padding: 1rem 0;
		font-size: 14px;
		margin-left: 2rem;
		svg {
			max-width: 18px;
		}
		&-time,
		&-user {
			color: $color-text;
		}
		&-star {
			color: $color-primary;
		}
		&-level {
			padding: 5px 8px;
			border-radius: 5px;
			background-color: #46bea7;
			text-align: center;
		}
	}

	&-discount {
		position: absolute;
		top: 0;
		right: 0;
		width: 12rem;
		height: 5rem;
		background-color: $color-primary;
		@include display-flex(center);
		border-bottom-left-radius: 3.5rem;
		@include set-font(1.6rem, 300);
		column-gap: 4px;
	}

	&-pic {
		@include d-flex-column(center);

		& img {
			width: 27rem;
			height: 100%;
			object-fit: cover;
			align-self: baseline;
			border-radius: 15px;
			@include respond(sm-laptop) {
				width: 26rem;
			}
			@include respond(big-phone) {
				width: 100%;
				height: 30rem;
			}
		}
	}

	&-info {
		flex-grow: 1;

		padding: 2rem 0 0 2rem;
	}
	&-content {
		width: 100%;
		height: 100%;
		padding-right: 2.7rem;
		@include d-flex-column;
		align-items: flex-start;
	}

	&-box {
		@include display-flex;

		border-radius: 10rem;
		margin: 2rem 0;
		width: 122%;

		// margin-right: -12rem;
		& img {
			height: 100%;
			min-width: 12rem;
			max-height: 10rem;
			object-fit: cover;
		}
		& .card-profile {
			margin-left: 3.5rem;
		}
	}
	&-details {
		position: relative;

		& > h4 {
		}

		&-time {
			@extend .d-flex;
			@include set-font(1.8rem, 600);
			margin-bottom: 0.8rem;

			& svg {
				max-width: 2.4rem;
				margin-left: 1rem;
				color: $color-black;
			}
		}

		&-price {
			@extend .d-flex;
			@include set-font(1.8rem, 600);
			line-height: 1.6;
			& svg {
				max-width: 2.7rem;
				height: auto;
				margin-left: 1rem;
				color: $color-black;
			}
			& .price:not(:last-child) {
				margin-left: 3rem;
			}
		}
	}
}

.card-profile {
	overflow: hidden;
	border-radius: 0 10rem 10rem 1rem;
}
.ant-rate {
	font-size: 1.9rem;
	@include respond(laptop) {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.5rem;
	}
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
	@include respond(laptop) {
		margin-left: 0.2rem !important;
	}
}
