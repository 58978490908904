.AskAndAnswer {
	height: 100%;
	&__form {
		bottom: 1rem;
		width: 100%;
		z-index: 10;

		&--input {
			display: flex;
			align-items: flex-end;
			column-gap: 5px;
			border: 1px solid rgba($color: #000000, $alpha: 0.3);
			border-radius: 2rem;
            padding: 0 5px;
			& .ant-input-affix-wrapper {
				align-self: center;
			}

			& .ant-input {
				@extend .custom-scrollbars;
			}
		}

		&--action {
			& #emoji {
				padding: 5px;
				& svg {
					max-width: 28px;
					max-height: 28px;
				}
			}
		}
        &--emoji{
            & .ant-popover-inner-content{
                padding: 5px 5px 0;
            }

            & .emoji-mart{
                border: 0;
            }

            & .emoji-mart-scroll{
				@extend .custom-scrollbars;
                scrollbar-color: $color-disable $color-gray-light;
            }

            & .emoji-mart-category-label{
                font-size: 14px;
            }
        }
	}
	// &__emogibox {
	//     // bottom: -3.5rem;
	//     bottom: 0.65rem;
	//     left: 3rem;
	//     z-index: 20;
	//     img {
	//         width: 3.2rem;
	//         height: 3.2rem;
	//     }
	//     @include respond(tablet) {
	//         img {
	//             width: 2rem;
	//             height: auto;
	//         }
	//     }
	// }
	// .emoji-mart {
	//     bottom: 3rem;
	//     left: 0rem;
	//     position: absolute;
	//     z-index: 30;
	// }
	// &__emptyBox {
	//     color: $color-text-black-light-2;
	//     width: 100%;
	//     font-size: 1.6rem;
	//     margin: 0 auto;
	//     top: 40%;
	//     transform: translateY(-50%);
	//     margin: 0 auto;
	//     text-align: center;
	//     align-items: center;
	//     justify-content: center;
	// }
	&__content {
		height: 93%;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 5px;
			border-radius: 20px;
			margin: 1rem 0;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: $color-white;
			border-radius: 20px;
			margin: 2rem 0;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #bfc1cf;
			border-radius: 20px;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #bfc1cf;
			border-radius: 20px;
		}
	}
	&__contentBox {
		& > * {
			margin-bottom: 4.9rem;
		}
	}
	&__askBox {
		background-color: $color-gray;
		position: relative;
		padding: 2rem;
		color: $color-text-black-light-2;
		border-radius: 1.5rem 1.5rem 0px 1.5rem;
		width: 80%;
		margin-right: 3.5rem;
		span {
			color: $color-text-blue;
			font-size: 1.4rem;
		}
		p {
			font-size: 1.6rem;
			word-break: break-word;
		}
	}
	&__askBox::after {
		content: '';
		position: absolute;
		height: 2.5rem;
		width: 2.5rem;
		bottom: 0;
		left: 99.5%;
		background-color: $color-gray;
		border-radius: 0.4rem;
		clip-path: polygon(100% 100%, 0 100%, 0 0);
	}
	&__answareBox {
		direction: ltr;
	}
	&__answare {
		background-color: $color-primary;
		position: relative;
		padding: 2rem;
		color: $color-text-black-light-2;
		border-radius: 1.5rem 1.5rem 1.5rem 0;
		width: 80%;
		margin-top: 4.9rem;
		margin-left: 3.5rem;
		text-align: right;
		span {
			color: $color-text-blue;
			font-size: 1.4rem;
		}
		p {
			font-size: 1.6rem;
			word-break: break-word;
		}
	}
	&__answare::after {
		content: '';
		position: absolute;
		height: 2.5rem;
		width: 2.5rem;
		bottom: 0;
		right: 99.5%;
		background-color: $color-primary;
		border-radius: 0.4rem;
		clip-path: polygon(0 100%, 100% 0, 100% 100%);
	}
	&__TimeBox {
		color: $color-text-black-light-3;
		font-size: 1.4rem;
		opacity: 0.8;
		margin-top: 0.6rem;
		&-ask {
			& > *:last-child {
				margin-right: 3rem;
			}
		}
		&-answer {
			& > *:last-child {
				margin-left: 3rem;
			}
		}
	}
	&__sendBtn {
		bottom: 1rem;
		z-index: 20;
		right: 1rem;
		@include respond(tablet) {
			width: 2rem;
			height: auto;
		}
	}
}
