.HeaderDiscount {
    padding: 1.2rem 10rem;
    width: 100%;
    @include respond(sm-laptop) {
        padding: 1rem;
    }
    &__circleBox {
        width: 10%;
        @include respond(sm-laptop) {
            width: 5%;
        }
    }
    &__LightorangeCircle {
        background-color: $color-primary-light-1;
        width: 5.7rem;
        height: 5.7rem;
        border-radius: 50%;
        bottom: -0.5rem;
        right: 4rem;
        @include respond(sm-laptop) {
            width: 4rem;
            height: 4rem;
            right: 2.5rem;
        }
        &-redborder {
            bottom: 0rem;
            right: 4.5rem;
            @include respond(sm-laptop) {
                right: 2.5rem;
            }
        }
    }
    &__imgbox {
        margin: 0 0rem 0 8rem;
        @include respond(sm-laptop) {
            margin: 0 0rem 0 4rem;
        }
    }
    &__img {
        width: 16rem;
        height: auto;
        @include respond(sm-laptop) {
            width: 10rem;
        }
    }
    &__discountBox {
        margin-right: 1rem;
        & > *:first-child {
            margin-bottom: 0.8rem;
        }
    }
    &__timerBox {
        background-color: $shadow-backgroud-light-white;
        border-radius: 1.5rem;
        padding: 1.6rem;
        width: 30%;
        margin-left: 3rem;
        @include respond(sm-laptop) {
            width: 50%;
            padding: 1rem 0.5rem;
        }
    }
    &__endCircleBox {
        margin-bottom: auto;
        margin-left: 5rem;
    }
    &__endDarkOrange {
        background-color: $color-primary-dark-1;
        width: 9.1rem;
        height: 9.1rem;
        @include respond(sm-laptop) {
            width: 6rem;
            height: 6rem;
        }
        border-radius: 50%;
        margin-top: 0rem;
        margin-right: 3rem;
    }
    &__endYellow {
        width: 8.8rem;
        height: 8.8rem;
        @include respond(sm-laptop) {
            width: 5.5rem;
            height: 5.5rem;
        }
        border-radius: 50%;
        right: 0.5rem;
        background-color: $color-yellow;
        bottom: -2.5rem;
        &-redborder {
            bottom: -1.9rem;
            right: -0.5rem;
        }
    }
    &__redBorderbig {
        border: 2px solid $color-red;
        border-radius: 50%;
        width: 8.7rem;
        height: 8.7rem;
        @include respond(sm-laptop) {
            width: 6rem;
            height: 6rem;
        }
    }
    &__midredBorder {
        border: 2px solid $color-red;
        border-radius: 50%;
        width: 7rem;
        height: 7rem;
        @include respond(sm-laptop) {
            width: 4rem;
            height: 4rem;
        }
    }
    &__discount {
        margin: auto 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        font-size: 2rem;
        @include respond(sm-laptop) {
            font-size: 1.5rem;
        }
    }
    &__endLightOrange {
        background-color: $color-primary-light-1;
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        bottom: -1.4rem;
        left: -3.6rem;
        @include respond(sm-laptop) {
            width: 4rem;
            height: 4rem;
        }
        &-redborder {
            bottom: -1rem;
            left: -3.2rem;
        }
    }
    &__titleBox {
        width: 70%;
        // margin-top: -3.5rem;
        margin-right: 4rem;
        @include respond(sm-laptop) {
            width: 100%;
        }
    }
    &__title {
        color: $color-text;
        font-size: 2rem;
    }
    &__midPart {
        & > *:last-child {
            margin-right: 5rem;
            @include respond(tablet) {
                margin-right: 0rem;
            }
        }
    }
}
