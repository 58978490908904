.CoursStandards {
	&__titrBox {
		margin: 5rem 0 0rem 0;
	}
	&__titr {
		color: $color-text-blue;
		font-size: 1.8rem;
		margin-bottom: 1.6rem;
	}
	&__titrs {
		color: $color-text-blue;
		font-size: 1.6rem;
		margin-right: 1.6rem;
	}
	&__text {
		color: $color-text-gray-dark;
		font-size: 1.6rem;
		border-right: 1px solid $color-primary;
        padding: 5px 2.6rem 1.5rem 0;
		padding: {
            top: 5px;
			bottom: 1.5rem;
			right: 2.6rem;
		}
		margin: {
			right: 1.3rem;
			bottom: 8px;
		}
		@include respond(tablet) {
			width: 90%;
			text-align: justify;
		}
	}
}
