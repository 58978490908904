.Detaile {
	padding: 2rem;
	@include respond(big-phone) {
		padding: 1rem;
	}
	&__hederBox {
		color: $color-blue;
		& > *:first-child {
			font-size: 1.8rem;
			margin-bottom: 0.5rem;
		}
		& > *:last-child {
			font-size: 1.6rem;
		}
	}
	&__txt {
		color: $color-text-light;
		font-size: 1.6rem;
		margin-top: 4rem;
	}
	&__exit {
		text-align: left;
		margin: 2rem;
		font-size: 2rem;
	}

}
