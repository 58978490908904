.Menu__sec {
	color: #ffffff;
	font-size: 14px;

	position: fixed;
	width: 100vw;
	top: 0;
	z-index: 1000;
	background-color: transparent;
	transition: background-color 200ms ease, top 300ms linear;
	&.sticky {
		background-color: $color-white;
		filter: $shadow-backgroud;
		top: 0;
		animation: appbar-visible 400ms ease-in;

		& .Menu__nav--profile > .profile__name {
			color: $color-black-light;
		}
	}
	&.visible {
		top: 0;
	}

	&.activeMenu {
		background-color: $color-blue;
	}
	&-dark {
		background-color: transparent;
	}
	.Menu__li {
		padding: 0 2.5rem;
		cursor: pointer;
		color: $color-white !important;
		@include respond(laptop) {
			padding: 0 1.8rem;
		}

		& a {
			// @include set-font(14px, 600, $color-white);
			&:hover {
				color: $color-primary-light !important;
			}
		}
		&.active {
			color: $color-primary-light !important;
		}
	}

	.menu_logo {
		// padding: 0 1rem;
		// z-index: 1051;

		@include respond(big-tablet) {
			order: 2;
			justify-content: flex-end;
		}

		& .logo {
			width: 5.8rem;
			height: 5.2rem;
			border-radius: 50%;
			padding: 1rem;
			@include display-flex(center);
			margin-left: 1.5rem;
			@include respond(big-tablet) {
				width: 4.4rem;
				height: 4.4rem;
				margin-left: 1rem;
			}

			& img {
				width: 100%;
				height: 100%;
				margin-left: -2px;
			}
		}
		& .logo-text {
			& svg {
				width: 100%;
				transition: all 300ms;
			}
			@include respond(big-tablet) {
				max-width: 10rem;
			}
		}
		&.dark {
			& .logo {
				background-color: $color-white;
			}
			& svg > path {
				fill: #ffffff;
				stroke: #ffffff;
			}
		}
		&.activeMenu {
			@include respond(big-phone) {
				& svg > path {
					fill: #ffffff;
					stroke: #ffffff;
				}
			}
		}
	}
	.Menu__nav {
		padding: 8px 3rem 8px 0;
		min-height: 8rem;
		width: 100%;

		position: relative;
		// overflow: hidden;
		transition: all 400ms ease-in-out;

		@include respond(big-tablet) {
			order: 1;
			position: fixed;
			right: -100vw;
			top: 0;
			padding-top: 10rem;
			padding-right: 0;
			width: 50vw;
			height: 100vh;
			background-color: $color-blue;
			display: flex;
			align-items: flex-start;
			flex-direction: column-reverse;
			justify-content: flex-end;

			&::before {
				content: none !important;
			}
		}

		@include respond(big-phone) {
			width: 100vw;
		}

		&.active {
			right: 0;
		}
		&::before {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			width: 100vw;
			height: 100%;
			z-index: -1;
			background-color: $color-blue-dark;
			border-bottom-right-radius: 85px;
		}

		&--profile {
			flex-direction: row-reverse;
			margin-right: 2rem;
			display: none;

			@include respond(big-tablet) {
				display: flex;
			}
			@include respond(smallest-phone) {
				margin-right: 1rem;
			}

			&.hidden {
				display: none;
			}

			& .profile__image {
				margin-left: 1rem;
				& img {
					width: 4rem;
					height: 4rem;
				}
			}
		}

		& .Menu__ul {
			width: 100%;
			@include display-flex;
			@include respond(big-tablet) {
				flex-direction: column;
				align-items: flex-start;
				padding-right: 3rem;
				& .Menu__li {
					&:not(:last-child) {
						margin-bottom: 2.5rem;
					}
				}
			}
		}

		&--btn {
			flex-direction: column;
			display: none;
			z-index: 1051;
			@include respond(big-tablet) {
				display: flex;
			}
			&:focus,
			&:active {
				&::after {
					opacity: 0;
				}
			}
			& span {
				background-color: $color-black;
				width: 2.6rem;
				height: 2px;
				border-radius: 6px;
				transition: all 200ms;

				&:nth-child(2) {
					margin: 4px 0;
				}
			}
			&.open {
				& > span {
					background-color: #fff;
					&:nth-child(2) {
						transform: translateX(2rem);
						opacity: 0;
					}
					&:nth-child(1) {
						transform: rotate(-45deg) translate(-4px, 4px);
					}
					&:nth-child(3) {
						transform: rotate(45deg) translate(-4px, -4px);
					}
				}
			}
			&.dark {
				& > span {
					background-color: $color-white;
				}
			}
		}

		&--wrapper {
			@include display-flex;
			width: 100%;
			@include respond(big-tablet) {
				width: auto;
			}
			@include respond(smallest-phone) {
				flex-basis: auto;
			}
		}

		&--backdrop {
			width: 100vw;
			height: 100vh;
			background-color: rgb(0 0 0 / 50%);
			position: fixed;
			top: 0;
			right: -100vw;
			z-index: 0;
			opacity: 0;
			transition: right 0.3s, opacity 0.4 0.2s ease-in-out;
			&.active {
				right: 0;
				opacity: 1;
			}
		}
	}
	& .Menu-wrapper {
		@include display-flex;
		column-gap: 3rem;
		width: 100%;
		height: 8rem;
		@include respond(big-tablet) {
			justify-content: space-between;
			flex-basis: 11rem;
		}
		@include respond(smallest-phone) {
			column-gap: 0;
		}
	}
	& .Menu_actions {
		@include display-flex;
		@include respond(big-tablet) {
			order: 3;
			flex-basis: 11rem;
			// flex-direction: row-reverse;
			justify-content: space-between;
			align-items: flex-start;
			&--profile {
				flex-direction: row-reverse;
				// margin-right: 2rem;
				& .profile__image {
					margin-left: 1rem;

					& img {
						width: 40px;
						height: 40px;
					}
				}
			}
			& .link {
				height: 28px;
				font-size: 12px;
			}
		}
		@include respond(smallest-phone) {
			flex-basis: auto;
		}

		& > .ant-btn {
			margin-left: 1rem;
			& .ant-badge {
				color: #fff;
				transition: all 0.3s 0.1s;
				@include respond(big-tablet) {
					color: $color-text;
				}
			}
			// &.dark,
			// &.activeMenu {
			// 	& .ant-badge {
			// 	}
			// }
			@include respond(big-tablet) {
				&.activeMenu {
					& .ant-badge {
						color: #fff;
					}
				}
			}
			&.dark {
				& .ant-badge {
					color: #fff;
				}
			}
		}
	}
	&--profile {
		position: relative;
		// margin-bottom: 3px;
	}

	p.profile__name {
		margin-left: 16px;
		color: #ccd5da;
		@include respond(tablet) {
			font-size: 12px;
		}
	}
	.profile__image {
		position: relative;
		img {
			width: 44px;
			height: 44px;
			border-radius: 50%;
			overflow: hidden;
			margin-bottom: 3px;
		}
	}
}
.Menu_actions--badge {
	& .ant-scroll-number {
		background-color: $color-primary;
		box-shadow: none;
		transform: none;
		right: -4px;
		left: auto;
		top: auto;
		bottom: -4px;
	}
}

.appbar {
	&__dahsboard {
		color: #ffffff;
		font-size: 14px;
		@include respond(big-tablet) {
			padding: 0 2rem;
			min-height: 8.5rem;
			display: flex;
			width: 100%;
			flex-direction: column;
			justify-content: center;
		}
		@include respond(phone) {
			padding: 0 1rem;
		}
		&.sticky {
			@include respond(tablet) {
				box-shadow: 0 4px 15px rgba($color: #000000, $alpha: 0.15);
			}
		}
		.Menu__li {
			padding: 0 2rem;
			@include respond(sm-laptop) {
				padding: 0 1.5rem;
			}
			@include set-font(14px, 600, $color-white);
			&:hover {
				color: $color-primary-light;
			}
			&.active {
				color: $color-primary-light;
			}
		}
		.logo {
			margin-left: 10px;
		}

		& .Menu-wrapper {
			column-gap: 3rem;
		}
		& .menu_logo {
			margin-right: 12rem;
			justify-content: center;
			@include respond(big-tablet) {
				margin-right: 0;
				order: 2;
			}
			@include respond(tablet) {
				padding-right: 0;
				padding-left: 0;
			}
			& .logo {
				width: 5.8rem;
				height: 5.2rem;
				border-radius: 50%;
				padding: 1rem;
				@include display-flex(center);
				margin-left: 1.5rem;
				@include respond(big-tablet) {
					width: 4.4rem;
					height: 4.4rem;
					margin-left: 1rem;
				}
				@include respond(tablet) {
					margin-left: 0.5rem;
				}

				& img {
					width: 100%;
					height: 100%;
					margin-left: -2px;
				}
			}
			& .logo-text {
				& svg {
					width: 100%;
					transition: all 300ms;
				}
				@include respond(big-tablet) {
					max-width: 10rem;
				}
			}
		}
		& .Menu_actions {
			@include display-flex;
			margin-left: 12rem;
			@include respond(sm-laptop) {
				margin-left: 4rem;
			}
			@include respond(big-tablet) {
				order: 3;
				margin-left: 0;

				justify-content: space-between;

				&--profile {
					flex-direction: row-reverse;
					margin-right: 2rem;
					@include respond(big-tablet) {
						margin-right: 1rem;
					}
					& .profile__image {
						margin-left: 1rem;

						& img {
							width: 40px;
							height: 40px;
						}
					}
				}
				& .link {
					height: 28px;
					font-size: 12px;
				}
			}

			& > .ant-btn {
				margin-left: 1rem;
				@include respond(phone) {
					margin-left: 0;
				}
				& .ant-badge {
					color: #fff;
					transition: all 0.3s 0.1s;
					@include respond(big-tablet) {
						color: $color-text;
					}
				}
				&.activeMenu {
					& .ant-badge {
						color: #fff;
					}
				}
			}
		}

		.Menu__nav {
			// overflow: hidden;
			min-height: 8rem;
			// width: 100%;

			transition: all 400ms ease-in-out;

			@include respond(big-tablet) {
				order: 1;
				position: fixed;
				right: -100vw;
				top: 0;
				padding-top: 8rem;
				padding-right: 0;
				width: 50vw;
				height: 100vh;
				background-color: $color-section;
				align-items: flex-start;
				flex-direction: column;
				justify-content: flex-start;
				&::before {
					content: none !important;
				}
			}

			@include respond(big-phone) {
				width: 100vw;
			}
			&.active {
				right: 0;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				width: 100vw;
				height: 100%;
				z-index: -1;
				background-color: $color-section;
				border-bottom-right-radius: 85px;
			}

			&--btn {
				flex-direction: column;
				display: none;
				z-index: 1051;
				@include respond(big-tablet) {
					display: flex;
					width: auto;
				}
				&:focus,
				&:active {
					&::after {
						opacity: 0;
					}
				}
				& span {
					background-color: $color-black;

					width: 2.6rem;
					height: 2px;
					border-radius: 6px;
					transition: all 200ms;

					&:nth-child(2) {
						margin: 4px 0;
					}
				}
				&.open {
					& > span {
						background-color: #fff;
						&:nth-child(2) {
							transform: translateX(2rem);
							opacity: 0;
						}
						&:nth-child(1) {
							transform: rotate(-45deg) translate(-4px, 4px);
						}
						&:nth-child(3) {
							transform: rotate(45deg) translate(-4px, -4px);
						}
					}
				}
			}

			&--wrapper {
				@include display-flex;
				width: 100%;
				@include respond(big-tablet) {
					width: auto;
				}
				@include respond(smallest-phone) {
					flex-basis: auto;
				}
			}

			&--backdrop {
				width: 100vw;
				height: 100vh;
				background-color: rgb(0 0 0 / 50%);
				position: fixed;
				top: 0;
				right: -100vw;
				z-index: 0;
				opacity: 0;
				transition: right 0.3s, opacity 0.4 0.2s ease-in-out;
				&.active {
					right: 0;
					opacity: 1;
				}
			}
		}

		& .Menu__ul {
			width: 100%;
			@include display-flex;
			@include respond(big-tablet) {
				flex-direction: column;
				align-items: flex-start;
				padding-right: 3rem;
				margin-right: 0;
				& .Menu__li {
					&:not(:last-child) {
						margin-bottom: 2.5rem;
					}
				}
			}
			&--sidebar {
				border-bottom: 1px solid $color-blue-light-1;
			}
		}

		.profile__image {
			position: relative;
			img {
				width: 44px;
				height: 44px;
				border-radius: 50%;
				overflow: hidden;
				// margin-bottom: 3px;
			}
			&-content {
				// display: none;
				div {
					background-color: #fff;
					color: $color-primary;
					border-radius: 50%;
					top: 25px;
					right: 0;
					padding: 0.1rem 0.2rem;
				}
			}
		}
	}
}

.appbar__profile {
	max-width: 5rem;
	max-height: 5rem;
	position: relative;
	cursor: pointer;
	&.ant-dropdown-open {
		& svg {
			transform: rotate(180deg);
		}
	}
	& .ant-avatar {
		border-radius: $br-item;
		width: 100%;
		height: 100%;
		background-color: #fff;
	}

	& .ant-badge-rtl .ant-scroll-number-custom-component {
		bottom: 0;
		right: 0;
		transform: none;
		top: auto;
		left: auto;
		@include display-flex(center);
		width: 2rem;
		height: 2rem;
		color: $color-text;
		border-radius: 50%;
		background-color: $color-white;
		padding: 5px;
		& svg {
			margin-top: 2px;
			transition: all 300ms ease-in 30ms;
		}
	}

	&--area {
		& h4 {
			font-size: 14px;
			color: $color-gray-dark;
		}
		& span {
			font-size: 12px;
			color: $color-gray-dark;
			font-weight: 700;
		}

		&-menu {
			& a {
				color: $color-gray;
			}

			& span {
				font-size: 14px;
				color: $color-error;
			}
		}
	}

	&--menu {
		&.ant-menu {
			min-width: 30rem;
			border-left: 0;
			border-radius: 2rem;
			filter: drop-shadow(0 5px 10px rgba($color: #000, $alpha: 0.16));
			margin-top: -1px;
			overflow: hidden;
		}
		& .ant-menu-item {
			@include display-flex(space-between);
			flex-direction: row-reverse;
			margin-top: 5px;
			height: 5rem;
			&:not(:last-child) {
				margin-bottom: 0;
			}
			&:hover {
				& .ant-menu-item-icon {
					margin-left: 4px;
				}
			}

			&.information {
				width: 100%;
				height: auto;
				cursor: default;
				margin-top: 0;
				margin-bottom: 0;
				&.ant-menu-item-selected {
					background-color: #fff;
					color: $color-text;
				}
				&:hover {
					color: $color-text;
				}
			}
		}

		& .ant-menu-item-icon {
			margin-right: 0 !important;
			// transform: rotate(90deg);
			max-height: 10px;
		}
		& .ant-menu-title-content {
			@include display-flex;
			column-gap: 1rem;
			width: 100%;

			& > svg {
				max-width: 2rem;
			}
			& #favorites path {
				fill: none;
				stroke-width: 1.4px;
			}
		}

		& .ant-menu-item-danger {
			&:hover {
				color: $color-error;
			}
		}
	}

	&--info {
		width: 100%;
		@include display-flex;
		padding: 1.5rem 0;
		column-gap: 1rem;

		& h4 {
			line-height: 1.2;
			@include set-font(16px, 700);
			margin-bottom: 2px;
		}

		&-content {
			line-height: 1.5;
			font-size: 12px;
		}

		& svg {
			width: 100%;
			height: 100%;
			max-width: 3rem;
			max-height: 3rem;
			color: $color-text;
		}
	}
}

.appbar {
	width: 100vw;

	position: fixed;
	top: 0;
	z-index: 1100;
	background-color: $color-blue;
	padding: 3.5rem 0;
	transition: ease 0.4s;

	&-scrolled {
		padding: 1rem 0;
	}
	&__container {
		@include respond(tablet) {
			flex-direction: row-reverse;
			justify-content: space-between;
		}
	}
	&__layout {
		@include respond(tablet) {
			position: fixed;
			right: -100vw;
			top: 0;

			padding: 12rem 8rem 6rem;

			display: flex;
			align-items: flex-start;

			flex-direction: column;
			background-color: $color-blue;
			width: calc(70vw + 5px);
			height: 100vh;
			// box-shadow: 1px 0 10px 0 rgba($color: #000, $alpha: 0.16);
			visibility: hidden;
			transition: all 0.6s;
		}

		@include respond(big-phone) {
			width: calc(100vw + 5px);
		}

		&.open-menu {
			right: -5px;
			visibility: visible;
		}
	}

	&__logo {
		@include respond(tablet) {
			flex-direction: row-reverse;
			column-gap: 4px;
		}
		&--img {
			width: 5.2rem;
			height: 5.2rem;
			border-radius: 50%;
			background-color: $color-white-1;
			padding: 7px;
			@include respond(tablet) {
				width: 30px;
				height: 30px;
				padding: 5px;
			}
		}
		& img {
			object-fit: contain;
			width: 100%;
			height: 100%;
			transform: translateX(-3px);
		}

		&--title {
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 900;
			color: $color-white-1;

			@include respond(tab-port) {
				font-size: 14px;
			}

			@include respond(tablet) {
				font-size: 12px;
			}
		}
	}

	&__menu {
		flex-grow: 1;
		justify-content: center;
		column-gap: 4.8rem;

		@include respond(tablet) {
			flex-direction: column;
			justify-content: flex-start;
			margin-bottom: 4rem;
			row-gap: 4rem;
		}

		&--link {
			font-size: 14px;
			font-weight: 500;
			color: $color-white-1;
		}

		&--btn {
			flex-direction: column;
			z-index: 1050;
			display: none !important;

			@include respond(tablet) {
				display: flex !important;
			}
			& span {
				background-color: $color-white-1;
				width: 2.6rem;
				height: 2px;
				border-radius: 6px;
				transition: all 200ms;

				&:nth-child(2) {
					margin: 4px 0;
				}
			}
			&.open {
				& > span {
					background-color: #fff;
					&:nth-child(2) {
						transform: translateX(2rem);
						opacity: 0;
					}
					&:nth-child(1) {
						transform: rotate(-45deg) translate(-4px, 4px);
					}
					&:nth-child(3) {
						transform: rotate(45deg) translate(-4px, -4px);
					}
				}
			}
		}
	}

	&__action {
		&--badge {
			& .ant-scroll-number {
				background-color: $color-primary;
				box-shadow: none;
				transform: none;
				right: -4px;
				left: auto;
				top: auto;
				bottom: -4px;
			}
		}

		&--cart {
			&:hover {
				background-color: rgba($color: #fff, $alpha: 0.2) !important;
			}
		}
	}
}
///new css
.Menu_actions--profile {
    // width: 56px;
    // height: 56px;
    // border-radius: 50%;
    // background: red;
}