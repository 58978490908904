.TeacherInfo {
	background-color: transparent;
	height: 100%;

	grid-row: 1/3;
	@include respond(small-tablet) {
		grid-column: 1/2;
		grid-row: 2/3;
	}
	&-wrapper {
		display: grid;
		grid-template-columns: minmax(220px, 1fr) 8fr;
		grid-template-rows: 3rem auto;
		gap: 2rem;
		min-height: 80vh;
		@include respond(small-tablet) {
			min-height: auto;
			grid-template-columns: 1fr;
		}
		& h3 {
			grid-column: 2/3;
			grid-row: 1/2;
			@include respond(small-tablet) {
				grid-column: 1/2;
			}
		}
	}
	&__resume {
		grid-column: 2/3;
		grid-row: 2/3;
		@include respond(small-tablet) {
			grid-column: 1/2;
			grid-row: 3/4;
		}
		& .accordion {
			margin-top: 0;
		}
	}
	&__Position {
		position: -webkit-sticky;
		position: sticky;
		top: 10rem;
	}
	&__form {
		box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
		background-color: $color-white;
		width: 95%;
		@include respond(big-tablet) {
			width: 100%;
		}
		padding: 0.8rem 2rem 3rem 1.7rem;
		margin-top: 1rem;
		border-radius: 1.5rem;
	}

	&__imgBox {
		border-radius: 50%;
		border: 4px solid $color-green-dark;
		width: 10rem;
		height: 10rem;
		margin: 0 auto;
		img {
			width: 9rem;
			height: 9rem;
			border-radius: 50%;
			margin: 0 auto;
		}
	}
	&__name {
		color: $color-text;
		font-size: 1.6rem;
		margin-top: 1rem;
	}
	&__description {
		color: $color-text-black-light-2;
		font-size: 1.4rem;
		width: 90%;
		margin: 1rem auto;
		display: -webkit-box;
		line-clamp: 6;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
	&__socialBox {
		width: 80%;
		margin: 2rem auto;
		& img {
			display: block;
			border-radius: 50%;
			cursor: pointer;
			padding: 5px;
			transition: all 0.3s;
			&:hover {
				background-color: rgba($color: $color-text, $alpha: 0.2);
			}
		}
		& > * {
			align-self: center;
			justify-self: center;
		}
	}
	&__tags {
		margin-top: 1.9rem;

		@include respond(tablet) {
			text-align: right;
		}
		.ant-tag {
			background-color: rgba(229, 229, 229, 0.8);
			border-radius: 3.7rem;
			border: 0;
			font-size: 1.4rem;
			padding: 0.5rem 1.5rem;
			margin-bottom: 1rem;
		}

		&--loading {
			column-gap: 1rem;
			width: 95%;
			padding: 0 1rem;
			& .ant-skeleton-button {
				animation-name: ant-skeleton-loading-rtl !important;
			}
		}
	}
	&__loading {
		& .ant-skeleton-with-avatar {
			@include d-flex-column;
			margin-bottom: 2rem;

			& .ant-skeleton-header {
				padding-left: 0;
			}
		}
		& .ant-skeleton-title {
			margin: 2rem auto 0;
		}
		& .ant-skeleton-avatar {
			width: 10rem;
			height: 10rem;
		}
		& .ant-skeleton-button {
			width: 15rem;
			animation-name: ant-skeleton-loading-rtl !important;
		}
	}
	// &.sticky {
	//     background-color: transparent;
	//     top: 10rem;
	//     right: 3rem;
	//     animation: sidebar-visible 300ms ease-in;
	//     position: fixed;
	//     width: 15.3%;
	//     // & .Menu__nav--profile > .profile__name {
	//     //     color: $color-black-light;
	//     // }
	//     & > * {
	//         width: 100%;
	//     }
	// }
	// &.stickydisable {
	//     position: absolute;
	//     width: 95%;
	//     margin-right: 0;
	//     right: 0;
	// }
}
.TeacherInfo {
}
