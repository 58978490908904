.VideoPlayer {
	&__videoBox {
		margin: 1.8rem 0;
	}
	&__imgBox {
		@include display-flex(center);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		img {
			cursor: pointer;
			@include respond(big-phone) {
				max-width: 5rem;
				max-height: 5rem;
			}
		}
	}
	&__video {
		width: 100%;
		max-height: 50rem;

		border-radius: 1.5rem;
	}
}
