.About {
    &__list {
        position: relative;
        padding-right: 1.5rem;
        &::before {
            content: "";
            position: absolute;
            width: 1rem;
            height: 1rem;
            background-color: $color-blue-1;
            border-radius: 50%;
            top: 1rem;
            right: 0;
        }
    }
    &__header {
        margin-top: 1.8rem;
    }
}
