.course-card-banner {
  background-color: #47bea7;
  border-radius: 56.5px 10px 10px 56.5px;

  .typo {
    margin-left: 1rem;
  }

  div:last-child {
    img{
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: #fff;
    padding: 0.1rem;
  }
}

.suggest {
  margin-left: 0.5rem;
  padding: 0.6rem;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .typo {
    img {
      width: 4.8rem;
    }
  }
  div:last-child {
    width: 2.7rem;
    height: 2.7rem;
    display: flex;
    padding: 0.6rem;

    img {
      width: 1.4rem;
    }
  }
}
