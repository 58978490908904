.MasterSignUp {
	&__formBox {
		width: 75%;
		margin: 0 auto;

		@include respond(tablet) {
			width: 95%;
		}
		@include respond(big-desktop) {
			width: 50%;
		}
	}
	&__gridBox {
		gap: 2rem 10rem;
		@include respond(tablet) {
			gap: 2rem 5rem;
		}
		@include respond(phone) {
			grid-template-columns: 1fr;
		}
		@include respond(big-desktop) {
			gap: 1rem 5rem;
		}
	}
	&__textarea {
		margin-top: 2rem;
		@include respond(tablet) {
			text-align: right;
		}
	}
	&__ruleBox {
		margin-top: 2rem;
	}
	&__btn {
		display: inline;
	}
	&__btnBox {
		margin-top: 7rem;
	}
}
