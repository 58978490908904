//userbox
.UserBox {
  border: 2px solid rgba(246, 133, 33, 0.3);
  border-radius: 15px;
  padding: 10px;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 5fr;
  &__info {
    margin-right: 10px;
    h6 {
      font-size: 14px;
      color: $color-blue;
    }
    p {
      font-size: 12px;
      color: $color-text;
    }
    &--des {
      margin: 5px;
    }
  }
  &__avatar {
    border-radius: 50%;
    overflow: hidden;
    width: 63px;
    height: 63px;
    img {
      width: 63px;
      height: 63px;
    }
  }
}
//MyMassagescontainer

.MyMassages {
  display: grid;
  grid-template-columns: 2fr 3fr;
  border-radius: 15px;
    overflow: hidden;
  &__user {
    &--col {
      background-color: rgba($color: #e5e5e5, $alpha: 0.5);
      padding: 15px;
      max-height: 70vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        opacity: 0;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        opacity: 0;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        opacity: 0;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        opacity: 0;
      }
    }
  }
}
//EmptyChatroom
.EmptyChatroom {
  width: 100%;
  height: 100%;
  @include display-flex(center);
}

.ChatroomUser {
  max-height: 70vh;
  height: 70vh;
  &__profile{
    padding: 10px;
    background-color: rgba($color: #F68521, $alpha:0.4);
    p{
      margin-right: 10px;
    }
  }
 &__avatar{
   width: 55px;
   height: 55px;
   overflow: hidden;
   border-radius: 50%;
   img{
     width: 55px;
     height: 55px;
   }
 }
 
  .Chatroom {
    .AskAndAnswer__answare{
      margin-top: 10px;
      span{
        color: $color-blue;
      }
    }
    .AskAndAnswer__contentBox > * {
      margin-bottom: 15px;
  }
    &__container {
      padding-top: 15px;
    }
    max-height: 56vh;
    overflow: hidden;
    height: 56vh;
    &::-webkit-scrollbar {
      opacity: 0;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      opacity: 0;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      opacity: 0;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      opacity: 0;
    }
  }
}
