.ChangePassword{
   h2{
       font-size: 2.3rem;
       color: $color-blue;
   }
    &__body{
        width: 100vw;
        height: 100vh;
        background-color: #f3f3f338
    }
    .ChangePassword__form{
        width: 375px;
        padding: 15px;
     }
     .input{
         margin: 10px 0;
     }
     .button__default{
         width: 100%;
         margin-top: 20px;
     }
}