.MyCourses {
    &__Card {
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 0px #fff;
        transition: all 0.1s;
        &:hover {
            box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
            border: 0px solid rgba(0, 0, 0, 0.15);
        }
        .ant-progress-circle .ant-progress-inner {
            width: 40px !important;
            height: 40px !important;
            font-size: 16px !important;
        }
        &--Link {
            color: $color-primary;
            cursor: pointer;
        }
        &--header {
            width: 100%;
        }
        &--body {
            height: 100px;
            font-size: 1.6rem;
            margin: 15px 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            &-row {
                width: 100%;
            }
            &-p {
                font-size: 2rem;
                color: $color-blue;
            }
            span {
                color: $color-blue;
            }
        }
    }
    &__cardDetail {
        & > *:not(:last-child) {
            margin-left: 2rem;
        }
    }
}
