.ContentDetail {
    &__downloadLink {
        font-size: 2rem;
        margin-top: 2rem;
        color: $color-primary;
    }
}
.sidebar-quize{
    display: flex;
    align-items: center;
    svg{
        width: 25px;
        height: 25px;
        margin-left: 8px;
        g{
            fill: $color-primary;
        }
    }
}