

$red: #ef2f3c;
$white: #f6f4f3;
$blue: #276fbf;
$dark: #183059;
$yellow: #f0a202;
.comming-soon{
    .input {
        width: 268px;
        input{
            &:focus{
                outline: none;
            }
        }
    }
    .input__field, .input textarea, .input .ant-select .ant-select-selector, .input .ant-input-textarea {
      margin-top: 0;
      border-radius: 50px;
       font-size: 1.6rem !important;
       height: 5rem;
    }
    .input__message {
        color: #ff5a67;
        margin-right: 1rem;
        font-size: 12px;
        position: absolute;
        right: 0;
        bottom: -42px;
       
    }
    &-container {
        clip-path: ellipse(51% 85% at 50% 15%);
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        overflow: hidden;
        width: 100%;
        height:100vh;
        background-color: #022f47;
        padding: 0 12rem;
    
        @include respond(tab-port) {
            clip-path: none;
            flex-direction: column-reverse;
            padding: 0 5rem;
        }
    
        .logo {
            position: absolute;
            top: 35px;
            left: 35px;
            width: 11.2rem;
            height: 10rem;
    
            @include respond(tab-port) {
                width: 5.5rem;
                height: 5rem;
            }
        }
    
        &-2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            .form {
                width: 500px;
                display: flex;
                align-items: center;
                justify-content: center;
    
                @include respond(tab-land) {
                    justify-content: left;
                    // align-self: flex-end;
                }
                @include respond(tab-port) {
                    justify-content: center;
                    margin-bottom: 3rem;
                }
            }
    
            form {
                margin-top: 3rem;
                width: 62%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
    
                input::placeholder {
    
                    @include respond(phone) {
                        font-size: 1.3rem;
                    }
                }
    
                .subscribe {
                    display: inline-block;
                    padding: 10px;
                    width: 100%;
                    height: 5rem;
                    border-radius: 50px;
                    border: 1px $white solid;
                    font-size: 1.6rem;
                    overflow: hidden;
                    text-align: start;
                }
    
                button {
                    border-radius: 50px;
                    border: none;
                    color: #f6f4f3;
                    height: 5rem;
                    position: absolute;
                    background-color: #549a81;
                    font-size: 1.6rem;
                    left: 0;
                    padding: 1rem 3rem;
                    font-family: "Yekan";
    
                    @include respond(tab-land) {
                        padding: 0.5rem 2.5rem;
                        font-size: 1.4rem;
                    }
                }
            }
        }
    
        img {
            width: 450px;
            height: 450px;
        }
    
        .content-img {
            @include respond(tab-port) {
                margin-top: 12rem;
                width: 300px;
                height: 300px;
            }
            @include respond(phone) {
                margin-top: 12rem;
                width: 230px;
                height: 230px;
            }
        }
    
        p {
            font-size: 2rem;
            color: #f6f4f3;
            margin-top: 1rem;
            max-width: 50rem;
            text-align: center;
    
            @include respond(tab-port) {
                font-size: 1.6rem;
                text-align: right;
            }
        }
    }
    
    h1 {
      //  font-family: $font;
        text-align: center;
        font-size: 6em;
        // text-transform: uppercase;
        letter-spacing: 0px;
        color: $white;
    
        @include respond(tab-port) {
            font-size: 4rem;
        }
        @include respond(phone) {
            font-size: 3rem;
        }
    }
    
    #timer {
        color: $white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
     //   font-family: $font;
        font-size: 2rem;
        column-gap: 4rem;
        margin-top: 3rem;
    
        @include respond(tab-land) {
            column-gap: 0.5rem;
            font-size: 1.5rem;
        }
    }
    
    .days,
    .hours,
    .minutes,
    .seconds {
        display: inline-block;
        padding: 10px;
        width: 80px;
        border-radius: 20px;
        border: 1px $white solid;
    
        @include respond(tab-land) {
            padding: 2px;
        }
    }
    
    .days {
        // background: $red;
    }
    
    .hours {
        // background: $white;
        // color: $dark;
    }
    
    .minutes {
        // background: $blue;
    }
    
    .seconds {
        // background: $yellow;
        @include respond(tab-land) {
            display: none;
        }
        @include respond(tab-port) {
            display: block;
        }
        .numbers {
        }
    }
    
    .numbers {
        // font-family: $big;
        // color: $dark;
        font-size: 2.5rem;
        @include respond(tab-land) {
            font-size: 3rem;
        }
    }
    
    .toast-true-hide,
    .toast-false-hide {
        display: none;
    }
    .toast-true-show,
    .toast-false-show {
        display: flex;
        width: 20rem;
        height: 5rem;
        background: #f6f4f3;
        color: #183059;
        position: absolute;
        top: 10rem;
        align-items: center;
        justify-content: center;
        padding: 0;
    
        p {
            color: #183059;
            margin: 0;
        }
    }
    
}
