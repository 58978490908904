.courses {
	padding-bottom: 3rem;
	min-height: 80vh;
	&__latest {
		.swiper-button-next,
		.swiper-button-prev {
			color: $color-black-light;
		}
	}
	&__popular {
		margin-top: 5rem;

		&--title {
			margin-bottom: 3rem;
			@include set-font(3rem, bold, $color-text);
		}
	}
	& .search {
		background-color: #ef801933;
		color: rgba(35, 62, 109, 0.7);
		font-size: 2rem;
		border: 2px solid #233e6db2;
		border-radius: 100px;
		font-weight: bold;
		&-img {
			position: absolute;
			left: 2rem;
			top: 50%;
			transform: translateY(-50%);
		}
		&-container {
			position: relative;

			width: 72%;
			margin: 3rem auto;
		}
		&::placeholder {
			color: rgba(35, 62, 109, 0.7);
			font-size: 2rem;
			background-color: transparent;
			font-weight: bold;
		}
		.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
			background-color: #ef801933;
		}

		.ant-input {
			&::placeholder,
			&:focus {
				background-color: transparent;
			}
		}
		.ant-input-affix-wrapper > input.ant-input {
			background-color: transparent;
		}
		input {
			background-color: transparent;
			// padding: 1.2rem;
			color: rgba(35, 62, 109, 0.7);
			font-size: 2rem;
			font-weight: bold;
			&:focus {
				background-color: #ef801933;
			}
			&::placeholder {
				color: rgba(35, 62, 109, 0.7);
				font-size: 2rem;
			}
			&::selection {
				background-color: transparent;
			}
		}
	}
	// .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
	//   background-color: transparent;
	// }
	&-filters {
		margin-top: 4rem;
		&__box {
			padding: 2rem;
			width: 100%;
			background-color: #f1f1f1;
			direction: rtl;
			border-radius: 20px;
		}

		&__title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 2.5rem;
			h6 {
			}
			i {
				font-weight: 300;
				font-size: 2.2rem;
			}
		}

		&__row {
			display: flex;
			align-items: center;
			margin-top: 2rem;
			@include respond(big-phone) {
				flex-direction: column;
			}
		}

		&__column {
			@include respond(big-phone) {
				width: 100%;
			}
			&:nth-child(2) {
				flex-basis: 45%;
				margin-right: 3rem;
				@include respond(big-phone) {
					margin-right: 0;
				}
			}
			&:nth-child(1) {
				flex-basis: 55%;
				@include respond(big-phone) {
					margin-bottom: 10px;
				}
			}

			.filters-title {
				margin-right: 1.2rem;
			}

			.filters-items {
				background-color: #fff;
				border-radius: 2rem;
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
				justify-content: space-around;
				font-size: 1.7rem;
				margin-top: 1.6rem;

				li {
					padding: 0.8rem;
					cursor: pointer;
					width: 100%;
					height: 100%;
					text-align: center;
					border-radius: 2.5rem;
					margin: 0 4px;
				}

				&__active {
					border: 3px solid #fea219;
					color: #fea219;
					font-weight: 700;
				}
			}
		}
	}
}
.Courses__searchTitle {
	font-size: 1.8rem;
	color: $color-black;
	margin-top: 12rem;
	margin-bottom: 2.4rem;
}
.Courses__img {
	width: 43rem;
	height: 38rem;
	margin: 0 auto;
	position: relative;
	// top: 30%;
	transform: translateY(-50%);
}
.Courses__imgBox {
	text-align: center;
	width: 100%;
	margin-top: 2rem;
	height: 97vh;
}
//swiper configs
.swiper-button-next {
	z-index: 10000000000000000000000;
}

//paging configs
.paging-button {
	background-color: #e5e5e5;
	color: $color-text;
	border: none;
}
.FavoriteCourse {
	margin-bottom: 40px;

	&__slider {
		.slick-slider {
		}

		.slick-slider .slick-track,
		.slick-slider .slick-list {
			transform: translate3d(0, 0, 0);
			direction: rtl;
		}
		.slick-track {
			margin-right: 0;
		}
		.card-sm {
			margin: 5px 1.2rem;
			direction: rtl !important;
		}
	}
}
.NextArrow__icons {
	img {
		transform: rotate(180deg);
	}
}
