// Colors

// ------------ Primary ------------
$color-primary: #f68521;
$color-primary-light: #ffa44a;
$color-primary-light-1: #ffc700;
$color-primary-dark: #cf6311;
$color-primary-dark-1: #ff9900;
$color-primary-dark-2: #ef8019;
$color-white: #fff;
$color-white-light: rgba(243, 243, 243, 0.7);
$color-white-1: #f5fbff;

// ------------ Background ------------
$color-blue: #022f47;
$color-blue-dark: #012f47;
$color-blue-light: #042f47;
$color-blue-light-1: #0f485c;
$color-progress: rgba(229, 229, 229, 0.702);
//------------blue----------
$color-blue-1: #002977;
$color-blue-2: rgba(35, 62, 109, 0.7);
$color-blue-3: rgb(59, 128, 248);
$color-green: #47bea7;
$color-green-opacity: rgba(71, 190, 166, 0.6);
$color-green-dark: #46bea7;
$color-phosphor: #b4d236;
$color-phosphor-opacity: rgba(179, 210, 54, 0.6);
$color-section: #1a6577;
$color-section-opacity: rgba(26, 100, 119, 0.6);

// ------------ black ------------
$color-black-light: #010101;
$color-black-light-1: rgba(18, 18, 18, 0.8);
$color-black: #000;
$color-text-black-light-2: rgba(18, 18, 18, 0.7);
$color-text-black-light-3: rgba(18, 18, 18, 0.79);
$color-text-black-light-4: rgba(18, 18, 18, 0.3);

//------------------gray----------------
$color-gray: #e5e5e5;
$color-gray-light: #ededed;
$color-gray-light-1: rgba(229, 229, 229, 0.6);
$color-gray-dark: #bbbbbb;
$color-grey-dark-1: #707070;
$color-dark-gray: rgba(0, 0, 0, 0.2);
//-----------------------yellow-------------
$color-yellow: #ffe661;
$color-yellow-dark: #fcca46;

//--------------red-------------
$color-red: #f14242;
//------------green---------
$color-green-light: #0dca78;

// ------------ Text ------------
$color-text: #121212;
$color-text-gray: #12121280;
$color-text-blue: #002171;
$color-text-light: #595959;
$color-text-light-1: rgba(
	$color: $color-text,
	$alpha: 0.6,
);
$color-text-gray-dark: rgba(
	$color: $color-text,
	$alpha: 0.8,
);
$color-disable: #888888;
$color-success: #329c00;
$color-success-light: #b5d335;
$color-error: #ff5a67;

$color-gradient: linear-gradient(
	140deg,
	rgba(239, 128, 25, 1) 30%,
	rgba(249, 168, 37, 1) 67%
);
$color-gradient-discount: linear-gradient(
	180deg,
	#20c086 -12.08%,
	rgba(32, 192, 134, 0) 117.94%
);
//------------------------
$shadow-button: drop-shadow(
	0 4px 6px
		rgba(
			$color: #000,
			$alpha: 0.2,
		)
);
$shadow-button-1: 0px 4px 6px rgba(13, 48, 107, 0.5);
$shadow-backgroud: drop-shadow(
	0px 4px 30px
		rgba(
			$color: #000,
			$alpha: 0.25,
		)
);
$shadow-backgroud1: 0px 2px 8px 2px
	rgba(
		$color: #000,
		$alpha: 0.15,
	);
$shadow-backgroud-light-white: rgba(
	$color: $color-white,
	$alpha: 0.5,
);
$color-ppoup-backgroud: rgba(246, 133, 33, 0.5);
$color-editor-back: #272822;
// Fonts

$font-primary: 'vazir';
$font-primary-medium: 'vazir-medium';
$font-primary-bold: 'vazir-bold';

$fonts: (
	regular: $font-primary,
	medium: $font-primary-medium,
	bold: $font-primary-bold,
	extra: $font-primary-bold,
	black: $font-primary-bold,
);

$br-item: 2rem;
$br-box: 3rem;

// RESPONSIVE BREAKPOINTS
$bp-xl: 75em; // 1200px
$bp-lg: 62em; // 992px
$bp-md: 48em; // 768px;
$bp-sm: 36em; // 576px;
$bp-xs: 30em; // 480px;

//box shadow
$box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.15);
