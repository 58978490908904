.Search {
    background-color: transparent;
    height: 100%;
    &__input {
        position: -webkit-sticky;
        position: sticky;
        top: 7.99rem;
        background-color: white;
        width: 100%;
        z-index: 10000;
    }
    &__boxDisplay {
        background-color: $color-white;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        width: 99%;
        box-shadow: $shadow-backgroud1;
        margin: 0 auto;
        height: auto;
        padding: 2rem;
    }
    &__contentBox {
        box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
        background-color: $color-white;
        border-radius: 1.5rem;
        padding: 0.8rem;
        p {
            color: $color-blue-2;
            font-size: 1.8rem;
            margin-right: 2rem;
        }
        img {
            width: 11.3rem;
            height: 6.68rem;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
    }
    &__lastsearches {
        p {
            color: $color-blue-2;
            font-size: 1.8rem;
            margin-right: 0.7rem;
        }
        img {
            width: 1.8rem;
            height: auto;
        }
    }
    &__footer {
        & > *:last-child {
            width: 3.6rem;
            height: auto;
        }
    }
}
