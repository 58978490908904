.input {
	.ant-select-rtl .ant-select-arrow {
		right: initial;
		left: 20px;
		top: 61%;
	}
	&__label {
		font-size: 1.6rem;
		font-weight: 400;
		margin-right: 11px;
	}
	& .ant-input-affix-wrapper-focused,
	& .ant-picker-focused {
		box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
		border-color: transparent !important;
	}
	&__withborder {
		border-radius: 15px;
		border-color: $color-dark-gray;
		border: 1px solid $color-dark-gray;
		padding: 3px 24px;
		font-size: 1.4rem;
	}
	.ant-input-affix-wrapper {
		border-radius: 15px;
		border-color: $color-dark-gray;
		padding: 3px 24px;
		font-size: 1.4rem;

		&:not(.ant-input-affix-wrapper-disabled):hover {
			border-radius: 15px;
			border-color: $color-dark-gray;
			font-size: 1.4rem;
		}
	}
	& .ant-select .ant-select-selector {
		padding: 0px 7px !important;
	}
	&__field.ant-input.ant-input-rtl.input__error {
		border-color: $color-error;
	}

	&__field,
	& .ant-select .ant-select-selector,
	& .ant-input-textarea {
		margin-top: 10px;
		border-radius: 15px;
		border-color: $color-dark-gray;
		padding: 4px 24px;
		font-size: 14px;
		outline: none;
		height: 40px;
		&:focus {
			box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
			border-color: transparent !important;
		}
		&:hover {
			border-color: $color-dark-gray;
		}
		svg {
			width: 100%;
			height: 100%;
		}
	}
	& .input__error {
		border-color: $color-error !important;

		& .ant-select-selector {
			border-color: $color-error;
		}
	}

	&__message {
		color: $color-error;
		margin-right: 1rem;
		font-size: 12px;
	}

	// &__field {
	// 	&::after {
	// 	}
	// 	&.ant-picker {
	// 		width: 100%;

	// 		& input {
	// 		}
	// 	}
	// }

	& textarea {
		@extend .input__field;
		resize: none;
	}

	&__select {
		width: 100%;
		// & .ant-select-selector {
		// 	border-color: $color-text-light;
		// 	min-height: 5rem;
		// }

		& .ant-select-selection-item,
		& .ant-select-selection-placeholder {
			border-radius: 15px;
			border-color: $color-dark-gray;
			padding: 3px 24px;
			font-size: 1.4rem;
		}

		& .ant-select-arrow {
			width: 17px;
			color: $color-text;
		}

		&.ant-select .ant-select-selector .ant-select-selection-item {
			right: 2rem;
		}

		&--dropdown {
			& .ant-select-item-option-selected {
				background-color: rgba($color: $color-primary, $alpha: 0.4);
			}
		}
	}
	.checkbox__large {
		.ant-checkbox-inner {
			width: 2.6rem;
			height: 2.6rem;
		}
		.ant-checkbox-inner::after {
			top: 46%;
			width: 0.8rem;
			height: 1.6rem;
		}
	}
	&__check {
		font-size: 1.6rem;
		align-items: center;
		.ant-checkbox-inner {
			border-radius: 4px;
			border-color: $color-dark-gray;
		}

		& .ant-checkbox {
			top: 0;
		}
		// .ant-checkbox-inner {
		// 	top: 0;
		// 	left: 0;
		// 	border-radius: 8px;
		// 	border-color: $color-dark-gray;

		// }
		// .ant-checkbox-wrapper:hover .ant-checkbox-inner,
		// .ant-checkbox:hover .ant-checkbox-inner,
		// .ant-checkbox-input:focus + .ant-checkbox-inner {
		// 	border-color: $color-dark-gray !important;
		// }
	}
	&__mask {
		display: flex;
		flex-direction: column;
		& .input__field {
			border: 1px solid rgba(0, 0, 0, 0.2);
			transition: all 300ms;
			letter-spacing: 2px;
			direction: auto;
			text-align: center;
			width: 100%;
		}
	}

	&__absolute {
		position: relative;

		& .input__message {
			position: absolute;
			bottom: -2rem;
			right: 4px;
		}
	}
}
.input__select.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
	border-color: transparent !important;
}

.date__picker {
	border-radius: 15px;
	padding: 3px 24px;
	font-size: 1.4rem;
	border: 1px solid $color-dark-gray;
	width: 100%;
	&:disabled {
		opacity: 0.5;
	}

	&--wrapper {
		& .rmdp-shadow,
		.rmdp-ep-shadow::after {
			box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
		}
		& .rmdp-arrow-container {
			@include display-flex(center);
			transition: all 300ms;
			&:hover {
				background-color: $color-primary-dark;
			}
		}
		& .rmdp-arrow {
			border-color: $color-primary-dark;
			margin-top: 0;
		}

		& .rmdp-week-day {
			color: $color-primary-dark;
		}
		& .rmdp-day {
			&:not(.rmdp-disabled):not(.rmdp-day-hidden) {
				& span {
					transition: background 300ms;
					&:hover {
						background-color: $color-primary-light;
					}
				}
			}
			&.rmdp-today span {
				background-color: $color-primary-light;
			}

			&.rmdp-selected span:not(.highlight) {
				background-color: $color-primary;
				box-shadow: 0 0 3px $color-primary-light;
			}
		}
	}
}
.rmdp-container {
	width: 100%;
}
input.ant-input.ant-input-rtl.input__field {
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 100px #fff inset;
		-moz-box-shadow: 0 0 0 100px #fff inset;
		box-shadow: 0 0 0 100px #fff inset;
	}
	&:autofill {
		-webkit-box-shadow: 0 0 0 100px #fff inset;
		-moz-box-shadow: 0 0 0 100px #fff inset;
		box-shadow: 0 0 0 100px #fff inset;
	}
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
	padding: 0 9px;
}
.Textarea {
	grid-column: 1 /-1;
}

// .create__course--wrapper {
// 	.input__select.ant-select .ant-select-selector .ant-select-selection-item {
// 		right: 0 !important;
// 	}
// }
.ant-input-clear-icon {
	display: none;
}
