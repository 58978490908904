.favorites {
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 1.5rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  height: min-content;

  &-main-title {
    position: relative;
    font-size: 2.5rem;
    font-weight: 700;
    color: #022f47;
    margin-bottom: 4rem;

    // &::before {
    //   // content: "";
    //   // width: 11.7rem;
    //   // height: 1rem;
    //   // background-color: #b3d136;
    //   // position: absolute;
    //   // border-radius: 10px;
    //   // top: 3.8rem;
    //   // right: 41.7rem;
    //   content: "";
    //   background-color: $color-primary;
    //   width: 3px;
    //   height: 90%;
    //   margin: 1.2rem 0;
    //   border-radius: 2px;
    //   position: absolute;
    //   @include respond(tablet) {
    //     content: none;
    //   }
    // }
  }

  &-items {
    // filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
    border-radius: 4rem;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    align-items: center;
    // box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
    padding: 3rem;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 37%;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba($color: #b3d136, $alpha: 0.8);
    //   z-index: -1;
    //   clip-path: polygon(17rem 0%, 100% 0%, 100% 100%, 0% 100%);
    //   @include respond(tablet) {
    //     left: 48%;
    //   }
    //   @include respond(phone) {
    //     left: 44%;
    //   }
    //   @include respond(smallest-phone) {
    //     left: 40%;
    //   }
    // }

    .logo {
      border-radius: 50%;
      background: #fff;
      padding: 0.8rem;
      img {
        width: 9.8rem;
      }
    }

    .typo {
      img {
        width: 21.6rem;
      }
    }

    &-detail {
      h2 {
        font-size: 3.2rem;
        font-weight: 700;
        margin-right: 3rem;
        margin-bottom: 3.6rem;
        color: #022f47;
      }

      &-content {
        width: 60.3rem;
        height: 15.7rem;
        padding: 3.1rem 3.2rem 2.7rem 0;
        background: rgba(69, 189, 166, 0.8);
        border-radius: 78.5px;
        margin-bottom: 1.6rem;
      }
    }
  }
}
