.WorkWithUs {
	&__logoBox {
        padding-top: 4rem;
	}
	
	img {
        width: 14rem;
        margin: 0 auto;
		height: auto;
	}
	&__content {
		margin-top: 4rem;
	}
	&__titlebox {
		margin-bottom:8px;
	}
	&__txtBox {
		margin-right: 1.9rem;
		width: 90%;
		@include respond(tablet) {
			width: 80%;
			text-align: justify;
		}
	}
	&__title {
		@include set-font(1.8rem, bold, $color-blue);
        line-height: 1.4;
	}
	&__txt {
		color: $color-text-gray-dark;
		font-size: 1.6rem;
		margin-bottom: 3.75rem;
	}
}
