%space-section {
	padding: 4rem 0;
}

.home {
	&__header {
		background-color: $color-blue;
		padding: 14rem 0;
		@include respond(big-phone) {
			padding-bottom: 6.5rem;
		}

		&--content {
			@include respond(big-phone) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		&--title {
			font-size: 6rem;
			font-weight: 900;

			@include respond(tablet) {
				font-size: 3rem;
			}
		}

		&--caption {
			font-size: 2rem;
			color: #b9b9b9;
			margin-top: 4px;
			@include respond(big-phone) {
				margin-bottom: 3rem;
				margin-top: 1rem;
				font-size: 14px;
				text-align: center;
			}
		}

		&--action {
			& .link {
				font-size: 1.8rem;
				height: 5.8rem;
				min-width: 20rem;
				@include respond(tablet) {
					min-width: 16rem;
					height: 5rem;
				}
				@include respond(big-phone) {
					font-size: 12px;
				}
			}

			& .link__default {
				border-color: #607d8b;
				color: #607d8b;
				border-width: 1px;

				&:hover {
					color: $color-white;
					background-color: #607d8b;
				}
			}

			&.dark .link__default {
				border-color: $color-white-1;
				color: $color-white-1;
				font-weight: 400;

				&:hover {
					color: $color-blue;
					background-color: $color-white-1;
				}
			}
		}
		&--wrapper {
			@include respond(big-phone) {
				flex-direction: column;
				align-items: center;
				row-gap: 1rem;
			}
		}
		&--pic {
			& svg {
				width: 100%;
				height: 100%;
				@include respond(big-phone) {
					max-width: 32rem;
				}
			}
		}

		&--search {
			margin-top: 8rem;
			max-width: calc(100% - 31rem);
			width: 100%;

			@include respond(sm-laptop) {
				max-width: 90%;
			}
			@include respond(tablet) {
				margin-top: 4rem;
			}
			@include respond(phone) {
				max-width: 95%;
			}
			& .ant-input {
				background-color: $color-blue-light-1;
				border: 0;
				border-radius: 5rem !important;
				min-height: 7rem;
				text-indent: 2rem;
				caret-color: $color-white-1;
				font-size: 1.8rem;
				color: $color-white-1;

				@include respond(big-phone) {
					min-height: 6rem;
				}

				&::placeholder {
					font-size: 1.8rem;
					color: rgba($color: $color-white-1, $alpha: 0.5);
				}
			}
			& .ant-input-group-addon {
				position: absolute;
				left: 2rem !important;
				top: 0.8rem;
				right: auto !important;
				width: auto;
				background-color: transparent;
				z-index: 10;
				@include respond(big-phone) {
					top: 4px;
					left: 1rem !important;
				}
			}

			& .ant-btn-primary {
				background-color: transparent;
				border: 0;
				color: $color-primary;
				opacity: 0.7;
				width: 5rem;
				height: 5rem;
				border-radius: 50% !important;
				padding: 0;
				@include display-flex(center);
				@include respond(big-phone) {
					padding: 1rem;
				}
			}
		}
	}

	&__introduction {
		position: sticky;
		top: 60px;
		overflow: hidden;
		height: 300vh;
		// height: 600vh;
		@include respond(big-tablet) {
			height: 250vh;
		}
		@include respond(tablet) {
			overflow: visible;
		}
		@include respond(phone) {
			padding: 0;
		}
		&--shape {
			position: absolute;
			top: 4rem;

			left: 0;
			border-radius: 0px 74px 74px 0px;
			width: 44px;
			height: 85vh;
			background-color: $color-blue-dark;
			@include respond(big-tablet) {
				display: none;
			}
		}

		&--wrapper {
			grid-template-columns: 60% 40%;

			padding-top: 1rem;
			padding-left: 5rem;
			padding-bottom: 34vmin;
			// min-height: 100vh;
			@include respond(big-tablet) {
				grid-template-columns: 3fr 2fr;
				grid-template-rows: max-content max-content;
				// gap: 2rem 3rem;
				padding-bottom: 10vmin;
				padding-left: 0;
			}

			@include respond(small-tablet) {
				grid-template-columns: 1fr;
			}
		}
		&--skip {
			position: relative;
			bottom: 12vh;
			right: calc(100% - 5rem);
			background-color: #42a789;
			width: 6rem;
			height: 6rem;
			border-radius: 50%;
			display: block;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				transform: scale(0.96);
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				width: 3.2rem;
				height: 6px;
				background-color: #fff;
				border-radius: 10px;
				top: 50%;
			}

			&::before{
				left: 4px;
				transform: rotate(44deg);
			}
			&::after{
				right: 4px;
				transform: rotate(-44deg);
			}
		}

		& .title__box {
			grid-column: 2/3;
			@include respond(big-tablet) {
				grid-column: 1/3;
				justify-self: center;
			}
			@include respond(small-tablet) {
				grid-column: 1;
			}
			& h2 {
				font-size: 24px;
				@include respond(tablet) {
					font-size: 18px;
				}
			}
		}

		&--content {
			margin-top: 10vh;
			position: relative;
			overflow: hidden;
			height: 100%;
			@include respond(tablet) {
				overflow: visible;
			}

			@include respond(small-tablet) {
				margin-top: 6rem;
			}

			& > .panel-text {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				visibility: hidden;
				transform: translateX(-100%);
			}

			& .home__header--action {
				@include respond(phone) {
					justify-content: center;
				}
			}

			& h3 {
				font-size: 18px;
				font-weight: 700;
				color: $color-blue;
			}

			& p {
				color: $color-blue-dark;
				line-height: 1.8;
			}
		}

		&--assets {
			margin-top: 5rem;
			position: relative;
			// overflow: hidden;
			@include respond(big-tablet) {
				display: flex;
				flex-direction: row-reverse;
				justify-content: flex-end;
				align-items: center;
				column-gap: 3rem;
			}

			@include respond(small-tablet) {
				justify-content: center;
			}
			&-pic {
				max-width: 438px;
				max-height: 330px;

				position: relative;

				display: flex;
				align-items: center;
				justify-content: center;

				@include respond(sm-laptop) {
					max-width: 43rem;
					max-height: 33rem;
				}

				@include respond(tab-port) {
					max-width: 33rem;
					max-height: 23rem;
				}

				&::after {
					content: '';
					position: absolute;
					width: 422px;
					height: 422px;
					background-color: rgba($color: #b5d336, $alpha: 0.2);
					border-radius: 50%;
					z-index: -1;
					@include respond(sm-laptop) {
						max-width: 42rem;
						max-height: 42rem;
					}

					@include respond(tab-port) {
						max-width: 32rem;
						max-height: 32rem;
					}
				}
			}
		}
		&--icons {
			position: absolute;
			top: -280px;
			right: -230px;

			width: 820px;
			height: 820px;
			transform: rotate(-45deg);
			@include respond(sm-laptop) {
				width: 82rem;
				height: 82rem;
				top: -25rem;
				right: -20rem;
			}

			@include respond(big-tablet) {
				position: static;
				width: auto;
				height: 100%;
				transform: none;

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-evenly;
				row-gap: 3rem;
			}

			&::before {
				content: '';

				width: inherit;
				height: inherit;
				position: absolute;
				z-index: -1;
				border: 1px dashed #42a789;
				border-radius: 50%;

				@include respond(big-tablet) {
					width: 0;
					height: 100%;
				}
			}
			&-item {
				width: 124px;
				height: 124px;
				background-color: $color-blue;
				border-radius: 50%;
				padding: 2.2rem;

				transform: scale(0.7);
				opacity: 0.8;
				transition: all 0.5s ease-in;
				@include display-flex(center);
				position: absolute;
				@include respond(sm-laptop) {
					width: 12.4rem;
					height: 12.4rem;
				}
				@include respond(big-tablet) {
					position: static;
					width: 8rem;
					height: 8rem;
					padding: 1.4rem;
				}

				& svg {
					width: 100%;
					height: 100%;
				}

				&:nth-of-type(1) {
					top: calc(50% - 80px);
					left: -60px;

					@include respond(sm-laptop) {
						left: -6rem;
					}
				}
				&:nth-of-type(2) {
					bottom: 40px;
					left: 80px;

					@include respond(sm-laptop) {
						left: 50px;
					}
				}
				&:nth-of-type(3) {
					bottom: -50px;
					left: 50%;
				}

				&.active {
					opacity: 1;
					transform: scale(1);
					&.website {
						transform: rotate(-47deg) scale(1);
						@include respond(big-tablet) {
							transform: scale(1);
						}
					}
					&.strategy {
						transform: rotate(-92deg) scale(1);
						@include respond(big-tablet) {
							transform: scale(1);
						}
					}
				}
			}
		}
	}

	&__process {
		background-color: $color-blue;
		padding: 5.5rem 0;
		position: relative;

		& .title__box h2 {
			color: $color-white-1;
		}
		& .wave-up {
			position: absolute;
			top: -4px;
			right: 10rem;
			@include respond(sm-laptop) {
				display: none;
				visibility: hidden;
			}
		}

		&--steps {
			margin-top: 8rem;
			width: 100%;
			max-width: 830px;

			& #dash {
				align-self: center;
				z-index: 1;
				&:nth-of-type(even) {
					transform: rotateY(180deg);
				}

				@include respond(big-phone) {
					transform: rotateY(50deg);
					&:nth-of-type(even) {
						transform: rotateY(230deg);
					}
				}
			}

			& #dash-large {
				align-self: center;
				transform: rotateY(180deg);

				@include respond(big-phone) {
					height: 100%;
					width: 100%;
					max-width: 285px;
				}
			}
		}

		&--item {
			background-color: $color-green;
			border-radius: 2.5rem;
			color: $color-blue;
			max-width: 430px;
			padding: 5px 0 5px 3.5rem;
			position: relative;
			z-index: 10;

			&::after {
				content: attr(data-count);
				position: absolute;
				bottom: -2rem;
				right: calc(50% - 2rem);
				width: 4rem;
				height: 4rem;
				border-radius: 50%;
				@include display-flex(center);
				font-size: 2.4rem;
				font-weight: 900;
				color: $color-white-1;
				background-color: $color-primary;
			}

			&:nth-of-type(even) {
				align-self: flex-end;
			}

			& span {
				flex: 1 0 40%;
				border: 0.8rem solid $color-blue;
				border-radius: 2.5rem;
				transform: translateY(-30px);

				& svg {
					width: 100%;
					height: 100%;
				}
				@include respond(big-phone) {
					// flex: 30%;
				}
			}

			& h4 {
				color: $color-blue;
				font-weight: 700;
				margin-bottom: 1rem;
			}
			& p {
				font-size: 12px;
			}
		}

		&--action {
			align-self: center;
			width: 100%;
			max-width: 60rem;

			& .link {
				height: 5.8rem;
			}
		}
	}

	&__courses {
		&--slider {
			width: 100%;
			margin-top: 7rem;
			height: 30rem;
			margin-bottom: 5rem;
			@include respond(tablet) {
				width: 100vw;
				height: auto;
			}
			& .swiper-slide {
				&:nth-of-type(even) .home__courses--item {
					&::before {
						background-color: #fea219;
						left: 3rem;
						right: auto;
					}
				}
				&:nth-of-type(5n + 1) .home__courses--item {
					&::before {
						background-color: #45bda6;
						right: 3rem;
					}
				}
				&:nth-of-type(3n + 3) .home__courses--item {
					&::before {
						background-color: #b5d336;
					}
				}
				&:nth-of-type(5n + 5) .home__courses--item {
					&::before {
						background-color: #044d66;
					}
				}
			}

			& .swiper-container {
				height: 100%;
			}

			& .swiper-button-prev,
			& .swiper-button-next {
				color: rgba($color: #000000, $alpha: 0.7);
			}

			& .swiper-slide-active .home__courses--item {
				transform: translateY(8rem);
				@include respond(tablet) {
					transform: none;
				}
			}
			& .swiper-slide-prev,
			& .swiper-slide-next {
				& .home__courses--item {
					transform: translateY(4rem);
					@include respond(tablet) {
						transform: none;
					}
				}
			}
		}
		&--item {
			background-color: $color-blue;
			border-radius: 2.5rem;
			padding: 1.5rem 3rem;
			position: relative;
			overflow: hidden;
			transition: all 500ms ease-in-out;
			margin: 0 3vw;
			&::before {
				content: '';
				position: absolute;
				top: -68%;
				width: 24rem;
				height: 24rem;
				border-radius: 50%;
				z-index: 1;
				background-color: #45bda6;
				right: 3rem;
			}

			& img {
				width: 100%;
				height: 100%;
				max-width: 9rem;
				margin-bottom: 1.5rem;
				z-index: 10;
			}

			& h4,
			& span {
				font-size: 16px;
				color: $color-white-1;
				font-weight: 700;
				line-height: 1.8;
			}
		}
	}
}
