.Technicalteam {
    &__content {
        margin-top: 2.4rem;
    }
    &__Box {
        background-color: $color-white;
        box-shadow: $shadow-backgroud1;
        border-radius: 1.5rem;
        padding: 1.2rem 2.7rem;
        margin-bottom: 1.7rem;
        & > *:last-child {
            color: $color-text-gray-dark;
            font-size: 1.8rem;
            @include respond(tablet) {
                width: 30%;
                text-align: left;
            }
        }
        @include respond(tablet) {
            justify-content: space-between;
            padding: 1rem 1.5rem;
        }
    }
    &__txt {
        width: 70%;
        @include respond(tablet) {
            width: 50%;
            justify-content: space-between;
        }

        & .link__text{
            min-width: fit-content;
        }
        & > *:last-child {
            color: $color-text-light-1;
            font-size: 1.8rem;
        }
    }
}
