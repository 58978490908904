.teacher-resume {
    &-title {
        position: relative;
        padding: 2rem;
        overflow: hidden;
        ::before {
            content: "";
            border-radius: 50%;
            width: 4rem;
            height: 4rem;
            background: $color-green-dark;
            position: absolute;
            top: 12px;
            right: -25px;
            z-index: -100;
        }
    }
    box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
}
