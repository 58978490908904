.NewCourse {
    &__title {
        color: $color-text;
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 7.3rem;
    }
    &__formBox {
        width: 40%;
        margin: 0 auto;
    }
    &__inputBox {
        margin-bottom: 2rem;
        & > *:first-child {
            width: 60%;
        }
        & > *:last-child {
            width: 30%;
            margin-right: 5.35rem;
            width: 17.85rem;
            height: 15.71rem;
        }
    }
    &__input {
        width: 100%;
    }
    &__btn {
        width: 16.8rem;
        margin: 5.2rem auto 0 auto;
        text-align: center;
    }
}
