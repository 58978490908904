.breadcrumbs {
	margin: 12rem 0 4rem 0;
	@include respond(tablet) {
		margin: 8rem 0 4rem 0;
	}

	&__dashboard {
		margin: 2rem 0 4rem ;
	}
	.ant-breadcrumb {
		display: flex;
		
		span {
			display: flex;
			align-items: center;
		}
	}
	
}
