@keyframes shadow-drop-center {
	0% {
		box-shadow: 0 0 0 0 transparent;
	}
	100% {
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.35);
	}
}
@keyframes appbar-visible {
	0% {
		top: -8rem;
	}
	100% {
		top: 0;
	}
}
@keyframes fade {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes showForm {
	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}
@keyframes sidebar-visible {
	0% {
		top: -2rem;
	}
	100% {
		top: 0;
	}
}
