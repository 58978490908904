.register {
	&__modal {
		min-width: 94.6rem;
		// max-height: 60rem;
		@include respond(tablet) {
			min-width: 0;
			// top: 3rem;
			@media only screen and (max-height: 62.5em) {
				top: 10rem;
			}
		}
		padding-bottom: 0;
		& .ant-modal-content {
			border-radius: 1.5rem;
		}
		& .ant-modal-body {
			padding: 0;
			border-radius: 1.5rem;
			position: relative;
			overflow: hidden;
		}
	}
	&__layout {
		@include display-flex("", stretch);
	}
	&__container {
		padding: 0;
		border-radius: 1.5rem;
		position: relative;
		overflow: hidden;
		min-height: 590px;
		background-color: $color-gray;

		&.active {
			& > .signIn {
				transform: translateX(-100%);
				opacity: 0;
			}
			& > .signUp {
				transform: translateX(0);
				opacity: 1;
				z-index: 5;
				animation: showForm 0.6s;
			}

			& .register__overlay {
				transform: translateX(100%);
			}

			& .register__overlay--content {
				transform: translateX(-50%);
			}

			& .register__content {
				&.signIn {
					transform: translateX(0);
				}
				&.signUp {
					transform: translateX(-20%);
				}
			}
		}
	}
	&__form {
		position: absolute;
		top: 0;
		height: 100%;
		transition: all 0.6s ease-in-out;
		width: 50%;
		@include respond(tablet) {
			width: 100%;
		}
		&.signUp {
			left: 0;
			opacity: 0;
			z-index: 1;
			transform: translateX(100%);

			& > .button__icon {
				left: 4px;
				right: auto;
				@include respond(tablet) {
					left: auto;
					right: 4px;
				}
			}
			& h2 {
				font-size: 2.2rem;
			}
		}
		&.signIn {
			right: 0;
			z-index: 2;
		}
		&.forgetPassword {
			padding: 9rem 4rem;
			transform: translateX(100%);
			background-color: $color-gray;

			z-index: 20;
			&.active {
				transform: translateX(0);
			}
			& form {
				height: 100%;
			}
			& .register__form--inner {
				justify-content: center;
				height: 100%;
			}
			& .register__form--wrapper {
				padding: 1.5rem 3rem;
			}
		}
		& > .button__icon {
			color: $color-error;
			padding: 2px;
			width: 45px;
			height: 45px;
			position: absolute;
			top: 4px;
			right: 4px;

			& svg {
				max-width: 32px;
				max-height: 32px;
			}
		}
		& .back {
			position: absolute;
			top: 6px;
			left: 6px;
			color: $color-text;
			height: 36px;
			padding: 4px 12px;
			min-width: 0;
		}

		& h2 {
			@include set-font(2.8rem, 700, $color-black);
			align-self: center;
		}

		&--wrapper {
			@include d-flex-column(center);
			height: 100%;
			padding: 1.5rem 6rem;
			@include respond(sm-laptop) {
				padding: 1.5rem 5rem;
			}
			@include respond(tablet) {
				max-width: 90%;
				margin: 0 auto;
			}
			@include respond(phone) {
				max-width: 100%;
				padding: 1.5rem 3rem;
			}
			& .button__text {
				color: $color-blue-dark;
				height: 32px;
				margin-bottom: 2rem;
				@include respond(tablet) {
					margin-bottom: 0;
					align-self: flex-start;
				}
			}
			& form {
				width: 100%;
			}

			& .ant-divider {
				color: $color-gray-dark;
				border-top-color: $color-gray-dark;
				&::after,
				&::before {
					top: 0;
				}
			}
		}
		&--inner {
			@include d-flex-column("", stretch);
			width: 100%;
			margin-bottom: 2rem;

			&.signUp {
				& .input__field {
					height: 35px;
				}
			}

			& .input__field {
				border-color: rgba($color: #fff, $alpha: 0.5);
				background-color: rgba($color: #fff, $alpha: 0.5);
				padding: 3px 1.5rem;
				margin-top: 4px;
				&.ant-input-affix-wrapper:hover {
					border-color: rgba($color: #fff, $alpha: 0.5);
				}
				& svg {
					max-width: 20px;
				}
			}
			& .input__label {
				font-size: 14px;
			}
			& .ant-input {
				background-color: transparent;
			}

			& .ant-checkbox-inner {
				border-radius: 2px;
				border: 0;
			}

			& > .button__default {
				background-color: $color-gray;
				// min-width: 14.5rem;
				&:hover {
					background-color: $color-primary;
				}
			}

			& .input {
				display: flex;
				flex-direction: column;
				position: relative;
				margin-bottom: 1.8rem;
				width: 100%;

				& .input__message {
					position: absolute;
					bottom: -1.9rem;
					font-size: 11px;
				}
			}
			& .ant-checkbox-wrapper {
				align-self: flex-start;
				padding-right: 1.2rem;
				margin-bottom: 1.5rem;
			}
		}
		&--actions {
			// display: grid;
			// grid-template-columns: repeat(2, 1fr);
			// grid-template-rows: repeat(2, auto);
			// gap: 1.2rem;
			// width: 100%;
			width: 100%;
			@include display-flex;
			column-gap: 1rem;

			& .social {
				padding: 1rem;
				min-width: 0;
				width: 40px;
				height: 40px;
			}

			& .link,
			a {
				background-color: $color-white;
				border: 0;
				filter: none;
				color: $color-text;
				font-weight: 300;
				font-size: 14px;

				&#google {
					flex-grow: 1;
				}

				& img {
					max-width: 3.6rem;
					max-height: 3.6rem;
				}
			}
		}
		&--other {
			display: none;
			@include respond(tablet) {
				display: flex;
				align-self: flex-start;
			}
		}
	}
	&__overlay {
		position: absolute;
		top: 0;
		right: 50%;
		width: 50%;
		height: 100%;
		overflow: hidden;
		transition: transform 0.6s ease-in-out;
		z-index: 100;
		@include respond(tablet) {
			display: none;
		}
		&--content {
			position: relative;
			right: -100%;
			height: 100%;
			width: 200%;
			background: linear-gradient(180deg, #217480 0%, #43baa4 100%) no-repeat 0 0 / cover;
			transform: translateX(0);
			transition: transform 0.6s ease-in-out;
		}
	}
	&__content {
		position: absolute;
		@include d-flex-column(center);
		padding: 4rem;
		top: 0;
		height: 100%;
		width: 50%;
		transform: translateX(0);
		transition: transform 0.6s ease-in-out;

		& h2 {
			@include set-font(3.2rem, 700, $color-white);
			margin-bottom: 1.5rem;
		}
		& p {
			@include set-font(1.8rem, 700, $color-white);
			margin-bottom: 3rem;
		}

		& img {
			max-width: 32rem;
			max-height: 22rem;
			margin-bottom: 3rem;
		}

		&.signIn {
			right: 0;
			transform: translateX(20%);
		}
		&.signUp {
			left: 0;
			transform: translateX(0);
		}
	}
}
