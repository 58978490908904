.shoppingcard {
  padding-top: 9rem;
  .card-bg{
    border: 1px solid #eaeaea;
    box-shadow: 0 0 0 #ffffff;
  }
  &__row{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 26px;
    align-items: flex-start;
    margin-top: 20px;
    @include respond(tablet){
      grid-template-columns: 1fr ;
    }
  }
  &__DegreeBox{
    .ant-radio-group{
      display: grid;
      label{
        margin: 8px 0;
      }
    }
  }
}
.primary-box {
  background: #ffffff;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  width: 100%;
}
.CostBox__empthy{
    opacity: 0.5;

}
.ExitModal{
  .ant-modal-content{
     width: 300px;
     margin: 0 auto;
  }
  .ExitModal__back{
    padding-top: 20px;
    text-align: center;
  }
}
.ShoppingList__NoCard{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  flex-direction: column;
  img{
    margin: 20px 0;
  }
  button{
    align-self: flex-end;
  }
}