.title__box {
	
	// padding: 1rem;
	// width: 100%;

	& h2 {
		@include set-font(2.4rem, 700, $color-blue-dark);
		position: relative;
		@include respond(phone) {
			font-size: 16px;
		}

		&::after {
			content: '';
			width: 8rem;
			height: 8px;
			border-radius: 1rem;
			background-color: $color-success-light;
			margin-top: 2px;
			position: relative;
			right: 0;
			display: block;
			@include respond(phone) {
				// width: 11rem;
				// height: 0.5rem;
				// right: -4rem;
			}
		}
	}
}
