.profile__uploader {
  &--cover {
    cursor: pointer;
    width: 12.2rem;
    height: 12.2rem;
    border-radius: 50%;
    overflow: hidden;
  }
  &--cover {
    .imagePreview {
      width: 100%;
      height: 100%;
      object-fit: cover;
      image-rendering: -webkit-optimize-contrast;
      cursor: pointer;
    }
  }
}
.UploadProfile--uploadcoverbox {
  .UploadProfile__input {
    display: none;
  }
  .UploadProfile__label {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $color-white;
    border-radius: 50%;
    padding: 4px;
    color: $color-success;
  }
}
.SocialInput {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  &__p {
    width: 121px;
    text-align: left;
    @include respond(sm-laptop) {
      font-size: 14px;
    }
  }
}
.uploadProfile__box {
  grid-column: 1/-1;
  align-items: center;
  &-name {
    font-size: 18px;
  }
  &-id {
    font-size: 16px;
    margin-top: 10px;
  }
}
