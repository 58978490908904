.accordion {
  padding: 0 1rem;
  margin-top: 3rem;

  & .ant-collapse-item {
    margin-bottom: 2rem;
    border: 1px solid rgba($color: #000000, $alpha: 0.3);
    border-radius: 1.5rem;
    transition: all 350ms;
    &:last-child {
      border-radius: 1.5rem;
    }

    & .ant-collapse-header {
      padding: 2px 16px;
    }
  }

  & .ant-collapse-item-active {
    border-color: transparent;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.15);
  }
  &.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
    & > * {
      line-height: 4rem;
    }
    div {
      margin: 0.5rem 0;
    }
  }
  & .ant-collapse-header {
    @include display-flex;
    flex-direction: row-reverse;
    width: 100%;
    min-height: 48px;
    font-weight: 600;
    & > span {
      flex: auto;
      order: 1;
      display: flex;
    }
  }

  & .ant-collapse-extra {
    margin-left: 2rem;
    flex-basis: 24%;
  }

  & .ant-collapse-content-box {
    padding: 1rem;
  }

  &__arrow {
    margin-left: 5px;
    transition: all 300ms;
    // @include display-flex(center);
    &.active {
      transform: rotate(180deg);
    }
  }

  &__title {
    font-weight: 500;
  }

  &__circle {
    & .ant-collapse-header {
      & > span {
        margin-right: 1.5rem;
      }
      &::after {
        width: 14px;
        height: 26px;
        background-color: $color-green-dark;
        border-radius: 20px 0 0 20px;
        position: absolute;
        right: -1px;
      }
    }
  }
  &__faq {
  }
}
