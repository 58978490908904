.latestCourse {
	margin-top: 20px;

	&__center {
		overflow: hidden;
		padding: 4rem 0;
		& .slick-arrow {
			z-index: 999;
			opacity: 0.6;
			&::before {
				display: none;
			}
			img {
				width: 2.5rem;
			}
		}
		.slick-prev {
			left: 10px;
		}
		.slick-next {
			right: 10px;
		}
		.slick-list {
			overflow: unset;
			width: 54%;
			margin: 0 auto;
			@include respond(laptop) {
				width: 60%;
			}
			@include respond(big-tablet) {
				width: 80%;
			}
			@include respond(tablet) {
				width: 100%;
			}
		}
		.slick-slide {
			direction: rtl;
			transform: scale(0.9);
			opacity: 0.6;
			transition: all 0.2s ease-in;
			@include respond(tablet) {
				transform: scale(1);
			}
		}

		& .slick-active {
			transform: scale(1.15);
			position: relative;
			z-index: 1000;
			opacity: 1;
			@include respond(tablet) {
				transform: scale(1);
			}
		}
	}
}
