.SideBarDiscount {
    padding: 1.4rem 0.9rem 1.2rem 1.1rem;
    background: $color-gradient-discount;
    border-radius: 1.5rem;
    &__yellowCircle {
        background-color: $color-yellow;
        width: 9.1rem;
        height: 9.1rem;
        @include respond(tablet) {
            width: 6rem;
            height: 6rem;
        }
        border-radius: 50%;
        // margin-right: auto;
    }
    &__DarkorangeCircle {
        background-color: $color-primary-dark-1;
        width: 5.7rem;
        height: 5.7rem;
        border-radius: 50%;
        top: 0;
        right: -1rem;
        @include respond(tablet) {
            width: 4rem;
            height: 4rem;
        }
        &-redborder {
            top: 0.4rem;
            right: -1.5rem;
        }
    }
    &__LightorangeCircle {
        background-color: $color-primary-light-1;
        width: 5.7rem;
        height: 5.7rem;
        border-radius: 50%;
        bottom: -0.5rem;
        left: 1.5rem;
        &-redborder {
            bottom: 0rem;
            left: 1rem;
        }
        @include respond(tablet) {
            width: 4.5rem;
            height: 4.5rem;
        }
        @include respond(smallest-phone) {
            width: 3.5rem;
            height: 3.5rem;
        }
    }
    &__redBorder {
        border: 2px solid $color-red;
        border-radius: 50%;
        width: 5.7rem;
        height: 5.7rem;
        @include respond(tablet) {
            width: 4rem;
            height: 4rem;
        }
    }
    &__smredBorder {
        border: 2px solid $color-red;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
    }
    &__circleBox {
        margin-right: auto;
        width: 20%;
    }
    &__discount {
        margin: auto 0;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    &__titleBox {
        width: 70%;
        margin-top: -3.5rem;
    }
    &__title {
        color: $color-text;
        font-size: 1.6rem;
    }
    &__discountBox {
        margin: 1.6rem 0 2.693rem 0;
        @include respond(tablet) {
            margin: 1rem 0 2rem 0;
        }
    }
    &__discountcode {
        background-color: $color-white;
        border-radius: 6.6rem;
        width: 9.3rem;
        padding: 0.5rem 0rem;
        height: 3rem;
        p {
            color: $color-text;
            font-size: 1.4rem;
        }
    }
    &__btn {
        padding: 0.5rem 0rem !important;
        font-size: 1.4rem !important;
        height: 3rem !important;
        width: 9.3rem !important;
        min-width: auto !important;
    }
    &__timerBox {
        background-color: $shadow-backgroud-light-white;
        border-radius: 1.5rem;
        padding: 1.6rem;
        width: 75%;
        margin: 0 auto 1rem auto;
    }
    &__timerTitle {
        p {
            display: inline-block;
            @include respond(tablet) {
                font-size: 1rem;
            }
        }
    }
    &__timer {
        margin-top: 1rem;
    }
    &__timer,
    .ant-statistic,
    .ant-statistic-content {
        color: $color-primary-dark-2;
        font-size: 1.6rem;
    }
    &__endPart {
        img {
            width: 16rem;
            height: auto;
        }
    }
    &__endDarkOrange {
        background-color: $color-primary-dark-1;
        width: 4.7rem;
        height: 4.7rem;
        border-radius: 50%;
        margin-top: 8rem;
        margin-right: 3rem;
    }
    &__endYellow {
        width: 5.2rem;
        height: 5.2rem;
        border-radius: 50%;
        right: 0.5rem;
        background-color: $color-yellow;
        bottom: -0.7rem;
        &-redborder {
            bottom: -0.5rem;
            right: -0.2rem;
        }
    }
    &__endLightOrange {
        background-color: $color-primary-light-1;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        bottom: -0.8rem;
        left: -1.2rem;

        &-redborder {
            bottom: -0.5rem;
            left: -1rem;
        }
    }
}
