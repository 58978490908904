.AboutDetaile {
	&__cover {
		width: 100%;
		text-align: center;
		& img {
			border-radius: 1.5rem;
			min-width: 50rem;
			max-height: 40rem;
			max-width: 100%;
			@include respond(big-phone) {
				min-width: fit-content;
				width: 100%;
			}
		}
	}

	&__hederBox {
		color: $color-text;
		margin-bottom: 1rem;
		& h2 {
			font-size: 2rem;
		}
	}
	&__txt {
		color: $color-text-light;
		font-size: 1.6rem;
		margin-top: 1rem;
	}
}
