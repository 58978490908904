.pagination {
	& li {
		font-family: 'Vazir', 'sans-serif';
	}

	&.mini {
		& .ant-pagination-prev,
		& .ant-pagination-next,
		& .ant-pagination-item {
			min-width: 32px;
			height: 32px;
			line-height: 32px;
		}

		& .ant-pagination-prev {
			margin-left: 6px;
		}
		& .ant-pagination-next {
			margin-right: 6px;
		}
	}

	& .ant-pagination-prev,
	& .ant-pagination-next {
		height: 38px;
		margin: 0 1.5rem;
		& .button {
			background-color: $color-gray;
			color: $color-text;
			border: 0;
			height: inherit;
			min-width: 0;
			&:hover {
				background-color: $color-primary;
				color: $color-white;
			}

			& svg {
				display: none;
			}
			@include respond(phone) {
				font-size: 13px;
				padding: 4px 10px;
				& span {
					display: none;
				}
				& svg {
					display: inline-block;
				}
			}
		}
	}

	& .ant-pagination-disabled {
		opacity: 0.5;
		& .button:hover {
			background-color: $color-gray;
			color: $color-text;
		}
	}
	& .ant-pagination-item {
		// border: 0;
		font-size: 16px;
		line-height: 32px;
		border-radius: 50%;
		@include respond(phone) {
			font-size: 14px;
		}
	}

	@include respond(phone) {
		& .ant-pagination-prev {
			transform: rotate(180deg);
		}
	}
}
